import { useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";

function ForgotPassId() {
  let { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      checkToken();
    }
  }, [id]);

  const checkToken = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/v1/users/forgot-pass-token-check`, {
        fToken: id,
      });
      if (response.data.status) {
        localStorage.setItem("forgotPassToken", response.data.data);
        window.location.href = `${process.env.REACT_APP_PUBLIC_SUBDOMAIN_URL}/reset-password`;
      } else {
        navigate("/errors/404");
      }
    } catch (err) {
      navigate("/errors/404");
    }
  };
}

export default ForgotPassId;
