import React, { useRef, useState } from "react";
import axios from "axios";
import Head from "../layout/head/Head";
import Content from "../layout/content/Content";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockBetween,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
} from "../components/Component";
import { Container, Modal, ModalBody } from "reactstrap";
import youtubeIcon from "../images/Youtube-icon.png";
import tiktokIcon from "../images/TikTok-icon.png";
import instaIcon from "../images/Insta-icon.png";
import Listing from "./ConnectionListing";
import { useEffect } from "react";
import { Spinner } from "reactstrap";
import toast from "react-hot-toast";
import { useOutletContext } from "react-router-dom";
function Connections() {
  // const [sm, updateSm] = useState(false);
  // const [modalLg, setModalLg] = useState(false);
  const [modalLg, setModalLg] = useOutletContext();
  const [YmodalLg, setYModalLg] = useState(false);
  const [connectionName, setConnectionName] = useState("");
  const [connectionListName, setConnectionListName] = useState("");
  const [data, setData] = useState([]);
  const [reBtnName, setReBtnName] = useState("");
  const [connectLoader, setConnectLoader] = useState(false);
  const [loader, setLoader] = useState(false);
  const inputRef = useRef();
  const toggleLg = () => setModalLg(!modalLg);
  const YtoggleLg = (name, btnName) => {
    setConnectionName(name);
    setConnectionListName(name);
    setReBtnName(btnName);
    setYModalLg(!YmodalLg);
  };

  useEffect(() => {
    setLoader(true);
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/v1/users/get-social-list`, {
        headers: {
          token: localStorage.getItem("accessToken"),
        },
      })
      .then((res) => {
        if (res.data.status) {
          // toast.success(res.data.message);
          setTimeout(() => {
            setData(res.data.data);
            setLoader(false);
          }, 1000);
        } else {
          toast.error(res.data.message);
          setTimeout(() => {
            setData(res.data.data);
            setLoader(false);
          }, 1000);
        }
      })
      .catch((err) => {
        toast.error("Error getting connections ", err.message);
        setData([]);
        setLoader(false);
      });
  }, []);

  useEffect(() => {
    // if ((window.location.href = `${process.env.REACT_APP_PUBLIC_SUBDOMAIN_URL}/connections`)) {
    //   setConnectLoader(false);
    // }
  }, []);

  const addActiveClass = (id) => {
    let menuItems = ["all", "source", "destination", "inactive"];
    let index = menuItems.indexOf(id);
    menuItems.splice(index, 1);
    document.getElementById(id).classList.add("active-connection-item");
    menuItems.map((item) => {
      document.getElementById(item).classList.remove("active-connection-item");
    });
  };

  const connectClick = (redirectUrl) => {
    // const screenWidth = 500;
    // const screenHeight = 600;
    // const left = window.screenX + (window.outerWidth - screenWidth) / 2;
    // const top = window.screenY + (window.outerHeight - screenHeight) / 2.5;
    // const title = `Google Authentication`;
    // const url = redirectUrl;
    // window.open(url, title, `width=${screenWidth},height=${screenHeight},left=${left},top=${top}`);
    // console.log(window.opener);
    localStorage.setItem("connectionName", connectionListName);
    window.location.href = redirectUrl;
  };

  const youtubeAuthentication = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/v1/auth/google-auth-url`, {
        headers: {
          token: localStorage.getItem("accessToken"),
        },
      })
      .then((res) => {
        // console.log(res);
        connectClick(res.data.data);
        connectLoader(false);
      })
      .catch((err) => {
        // console.log(err);
        connectLoader(false);
      });
  };

  const instagramAuthentication = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/v1/auth/insta-auth-url`, {
        headers: {
          token: localStorage.getItem("accessToken"),
        },
      })
      .then((res) => {
        //

        // console.log(res);
        connectClick(res.data.data);
        setTimeout(() => {
          setConnectLoader(false);
        }, 2000);
        // connectLoader(false);
      })
      .catch((err) => {
        // console.log(err);
        setConnectLoader(false);
      });
  };

  const tiktokAuthentication = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/v1/auth/tiktok-auth-url`, {
        headers: {
          token: localStorage.getItem("accessToken"),
        },
      })
      .then((res) => {
        // console.log(res);
        connectClick(res.data.data);
        connectLoader(false);
      })
      .catch((err) => {
        // console.log(err);
        connectLoader(false);
      });
  };

  // const publishVideo = async (files) => {
  //   const formData = new FormData();
  //   formData.append("file", files[0]);
  //   // const file = await fs.readFile("video.mp4", "binary");
  //   // formData.append("video", file);
  //   const videoResult = await fetch(
  //     `https://open-upload-i18n.tiktokapis.com/upload?upload_id=7357971331939518497&upload_token=5d790be6-aa20-0c10-7dda-18218c75f69f`,
  //     {
  //       method: "PUT",
  //       headers: {
  //         "Content-Range": "bytes 0-2581322/50000123",
  //         "Content-Type": "video/mp4",
  //         "Content-Length": "2581322",
  //       },
  //       body: formData,
  //     }
  //   );
  //   const videoData = await videoResult.json();
  //   console.log("videoData: ", videoData);
  // };

  return (
    <React.Fragment>
      <Head title="Connections" />
      <section className="container-fluid">
        <BlockHead size="sm" className="mx-4">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>My Connections</BlockTitle>
              {/* <BlockDes className="text-soft">
                  <p>Welcome to Campaign Management Dashboard.</p>
                </BlockDes> */}
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <div className="toggle-expand-content d-block">
                  <ul className="nk-block-tools g-3">
                    <li>
                      <Button className="btn-dim btn-white btn-outline-light" onClick={toggleLg}>
                        <Icon name="plus"></Icon>
                        <span>
                          {/* <span className="d-md-none">Add</span> */}
                          <span className="d-block" style={{ fontSize: "14px" }}>
                            ADD A NEW CONNECTION
                          </span>
                          <Modal size="lg" isOpen={modalLg} toggle={toggleLg}>
                            <ModalBody className="connection-modal">
                              <div className="mb-3 modal-head">
                                <button className="close modal-close" onClick={toggleLg}>
                                  <Icon name="cross" />
                                </button>
                                <div className="d-flex justify-content-center fw-bolder fs-20px">
                                  Select a Connection
                                </div>
                              </div>
                              <div className="d-flex justify-content-around">
                                <div
                                  className="d-flex flex-column justify-content-center col-3 p-3 modal-item"
                                  onClick={() => YtoggleLg("YouTube", "Connect")}
                                >
                                  <div className="d-flex justify-content-center">
                                    <img className="connection-icon" src={youtubeIcon} alt="Youtube Icon" />
                                  </div>
                                  <div className="text-align-center fs-16px fw-bold pt-2 connection-text">YouTube</div>
                                  {/* <div className="text-align-center">Source / Destination</div> */}
                                </div>
                                <div
                                  className="d-flex flex-column justify-content-center col-3 p-3 modal-item"
                                  onClick={() => YtoggleLg("TikTok", "Connect")}
                                >
                                  <div className="d-flex justify-content-center">
                                    <img className="connection-icon" src={tiktokIcon} alt="TikTok Icon" />
                                  </div>
                                  <div className="text-align-center fs-16px fw-bold pt-2 connection-text">TikTok</div>
                                  {/* <div className="text-align-center">Source / Destination</div> */}
                                </div>
                                <div
                                  className="d-flex flex-column justify-content-center col-3 p-3 modal-item"
                                  onClick={() => YtoggleLg("Instagram", "Connect")}
                                >
                                  <div className="d-flex justify-content-center">
                                    <img className="connection-icon" src={instaIcon} alt="Instagram Icon" />
                                  </div>
                                  <div className="text-align-center fs-16px fw-bold pt-2 connection-text">
                                    Instagram
                                  </div>
                                  {/* <div className="text-align-center">Source / Destination</div> */}
                                </div>
                              </div>
                            </ModalBody>
                          </Modal>
                          <Modal
                            size="lg"
                            isOpen={YmodalLg}
                            toggle={() => {
                              if (!connectLoader) setYModalLg(!YmodalLg);
                            }}
                            backdrop="static"
                          >
                            <ModalBody className="connection-modal">
                              <div className="mb-3 modal-head">
                                <button
                                  className="close modal-close"
                                  onClick={() => {
                                    if (!connectLoader) setYModalLg(!YmodalLg);
                                  }}
                                >
                                  <Icon name="cross" />
                                </button>
                                <div className="d-flex justify-content-center fw-bolder fs-20px text-capitalize">
                                  {connectionName}
                                </div>
                              </div>
                              <div className="d-flex flex-column mb-4">
                                <label className="fs-4 mb-1">Connection Name</label>
                                <input
                                  className="connection-item-list text-capitalize"
                                  defaultValue={connectionListName}
                                  onChange={(e) => setConnectionListName(e.target.value)}
                                  disabled={connectLoader}
                                  maxLength="15"
                                  ref={inputRef}
                                />
                              </div>
                              {connectionName == "YouTube" ? (
                                <div className="text-info fw-bold mb-4 ps-3">
                                  <ul>
                                    <li>
                                      Please ensure that your YouTube account should already have a YouTube channel
                                      associated with the account.
                                    </li>
                                  </ul>
                                </div>
                              ) : connectionName == "TikTok" ? (
                                <div className="text-info fw-bold mb-4 ps-3">
                                  <ul>
                                    {/* <li>
                                      Please ensure that your TikTok account should be set to private, if you wish to
                                      upload reel on TikTok.
                                    </li> */}
                                    <li>
                                      Please ensure that your TikTok account should be set to public, if you wish to
                                      fetch reel from TikTok and upload reel on TikTok.
                                    </li>
                                  </ul>
                                </div>
                              ) : connectionName == "Instagram" ? (
                                <div className="text-info fw-bold mb-4 ps-3">
                                  <ul>
                                    <li>
                                      Please ensure that your Instagram account is converted to Professional Creator or
                                      Professional Business.
                                    </li>
                                    <li>
                                      Please ensure that your Instagram account is connected to your Facebook Page.
                                    </li>
                                  </ul>
                                </div>
                              ) : (
                                ""
                              )}
                              <div className="d-flex justify-content-center mt-2">
                                {connectLoader ? (
                                  <button className="connect-btn ">
                                    <Spinner size={"sm"} />
                                  </button>
                                ) : (
                                  <button
                                    className="connect-btn  "
                                    onClick={() => {
                                      let text = connectionListName.trim();

                                      if (!text) {
                                        console.log("before empty", connectionListName);
                                        setConnectionListName("");
                                        console.log("after empty", connectionListName);
                                        toast.error("Invalid Input");
                                        inputRef.current.focus();
                                        return;
                                      }
                                      setConnectLoader(true);
                                      if (connectionName == "YouTube") {
                                        youtubeAuthentication();
                                      } else if (connectionName == "Instagram") {
                                        instagramAuthentication();
                                      } else if (connectionName == "TikTok") {
                                        tiktokAuthentication();
                                      }
                                      // setYModalLg(!YmodalLg);
                                      if (modalLg) {
                                        setModalLg(!modalLg);
                                      }
                                    }}
                                  >
                                    {reBtnName}
                                  </button>
                                )}
                              </div>
                            </ModalBody>
                          </Modal>
                        </span>
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block className="connection-block mx-4">
          <div className="d-flex connections-menu">
            <div className="connection-items active-connection-item" id="all" onClick={() => addActiveClass("all")}>
              All
            </div>
            {/* <div className="connection-items" id="source" onClick={() => addActiveClass("source")}>
              Source
            </div>
            <div className="connection-items" id="destination" onClick={() => addActiveClass("destination")}>
              Destination
            </div>
            <div className="connection-items" id="inactive" onClick={() => addActiveClass("inactive")}>
              Inactive
            </div> */}
          </div>
          {/* {data.length > 0 && !loader ? (
            <div className="connection-subMenu">
              <div>Type</div>
              <div className="c-subMenu-name">Name</div>
              <div>Status</div>
              <div className="pe-5">Expiry Date</div>
              <div></div>
            </div>
          ) : (
            ""
          )} */}
          {data.length > 0 && !loader ? (
            <div className="connection-list">
              <div className="connection-subMenu">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-5 col-lg-4 ">Type</div>
                    {/* <div className="c-subMenu-name">Name</div> */}
                    <div className="col-3 ">Status</div>
                    <div className="col-3 ">Expiry Date</div>
                    <div className="col-1 col-lg-2 "></div>
                  </div>
                </div>
              </div>

              {data.map((list, index) => {
                return (
                  // <React.Fragment>
                  <Listing list={list} key={index} YtoggleLg={YtoggleLg} setLoader={setLoader} />
                  // </React.Fragment>
                );
              })}
            </div>
          ) : loader ? (
            <div className="d-flex justify-content-center mt-4">
              <Spinner color="primary" />
            </div>
          ) : (
            <h4 className="text-center pt-5 mt-5">No connections found. Please add a new connection.</h4>
          )}
          {/* <input type="file" onChange={(e) => publishVideo(e.target.files)} /> */}
          {/* <Button onClick={publishVideo}>Upload Video</Button> */}
        </Block>
      </section>
    </React.Fragment>
  );
}

export default Connections;
