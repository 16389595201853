import React, { useState, useEffect } from "react";
import axios from "axios";
import Head from "../layout/head/Head";
import { useNavigate } from "react-router";
import { Button, PreviewCard, Icon } from "../components/Component";
import NameCreation from "./NameCreation";
import ChooseSourceType from "./ChooseSourceType";
import { Spinner } from "reactstrap";
import SourceAction from "./SourceAction";
import SourceDestination from "./SourceDestination";
import ConnectionDes from "./ConnectionDes";
import { Steps, Step } from "react-step-builder";
import toast from "react-hot-toast";

const CreateWorkFlow = () => {
  const [types, setTypes] = useState(0);
  const [workflowName, setWorkflowName] = useState("");
  const [sourceType, setSourceType] = useState("");
  const [sourceVideoType, setSourceVideoType] = useState("");
  const [destinationType, setDestinationType] = useState("");
  const [destinationVideoType, setDestinationVideoType] = useState("");

  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();

  const Header = (props) => {
    return (
      <div className="steps clearfix">
        <ul>
          <li className={types == 1 ? "first done" : types >= 1 ? "first done" : "first"}>
            <span
              className={types >= 0 ? "set_progress_custom_style  types_active" : "set_progress_custom_style "}
            ></span>
            <span className={types >= 0 ? "active_color" : " steps_text"} style={{ left: "-7px" }}>
              Name
            </span>
          </li>
          <li className={types == 2 ? "first done" : types >= 2 ? "first done" : "first"}>
            <span
              className={types >= 1 ? "set_progress_custom_style  types_active" : "set_progress_custom_style"}
            ></span>

            <span className={types >= 1 ? "active_color" : " steps_text"} style={{ left: "-7px" }}>
              Source
            </span>
          </li>
          <li className={types == 3 ? "first done" : types >= 3 ? "first done" : "first"} style={{ flexGrow: 0.1 }}>
            <span
              className={types >= 2 ? "set_progress_custom_style  types_active" : "set_progress_custom_style"}
            ></span>
            <span className={types >= 2 ? "step_text active_color" : " steps_text"} style={{ left: "-25px" }}>
              Destination
            </span>
          </li>
          {/* <li>
            <span
              className={types == 4 ? "set_progress_custom_style  types_active" : "set_progress_custom_style"}
            ></span>
            <span className={types >= 4 ? "active_color" : " steps_text"} style={{ left: "-15px" }}>
              Destination
            </span>
          </li> */}
        </ul>
      </div>
    );
  };
  const config = {
    before: Header,
  };

  const workflowAdd = async () => {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/v1/workflow/workflow-add`,
      {
        name: workflowName,
        source_id: sourceType.value,
        destination_id: destinationType.value,
        user_token: localStorage.getItem("accessToken"),
      },
      {
        headers: {
          token: localStorage.getItem("accessToken"),
        },
      }
    );

    if (response.data.status) {
      toast.success(response.data.message);
      setTimeout(() => {
        setLoader(false);
        navigate("/");
      }, 1000);
    } else {
      toast.error(response.data.message);
      setTimeout(() => {
        setLoader(false);
        navigate("/");
      }, 1000);
    }
  };

  const backBtn = () => {
    if (types == 0) {
      navigate(-1);
    } else {
      setTypes(types - 1);
    }
  };

  return (
    <React.Fragment>
      <Head title="Create WorkFlow" />
      {loader ? (
        <div className="d-flex justify-content-center mt-4">
          <Spinner color="primary" />
        </div>
      ) : (
        <section className="container">
          <h4>Create a New WorkFlow</h4>
          <Button onClick={() => backBtn(types)} className="btn-dim btn-white btn-outline-light me-5 mt-3">
            <Icon name="arrow-left-fill-c" />
            <div className="back-btn">Back</div>
          </Button>

          <div className="row">
            <PreviewCard className="col col-lg-6 progress-card">
              <div className="nk-wizard nk-wizard-simple is-alter wizard clearfix">
                <Steps config={config}></Steps>
              </div>
            </PreviewCard>
          </div>

          <div className="row">
            <div className="w-75 m-auto p-5 creating_work_form_custom_style">
              {types == 0 ? (
                <NameCreation setTypes={setTypes} setWorkflowName={setWorkflowName} workflowName={workflowName} />
              ) : types == 1 ? (
                <ChooseSourceType
                  setTypes={setTypes}
                  setSourceType={setSourceType}
                  sourceType={sourceType}
                  sourceVideoType={sourceVideoType}
                  setSourceVideoType={setSourceVideoType}
                />
              ) : // ) : types == 2 ? (
              //   <SourceAction setTypes={setTypes} />
              types == 2 ? (
                // <SourceDestination setTypes={setTypes} />
                <ConnectionDes
                  setTypes={setTypes}
                  setDestinationType={setDestinationType}
                  destinationType={destinationType}
                  setDestinationVideoType={setDestinationVideoType}
                  destinationVideoType={destinationVideoType}
                  workflowAdd={workflowAdd}
                  sourceType={sourceType}
                  setLoader={setLoader}
                />
              ) : (
                <ConnectionDes setTypes={setTypes} />
              )}
            </div>
          </div>
        </section>
      )}
    </React.Fragment>
  );
};

export default CreateWorkFlow;
