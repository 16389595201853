import React, { useState, useRef, useEffect } from "react";
import JoditEditor from "jodit-react";
import Head from "../layout/head/Head";
import { Block, BlockHead, BlockHeadContent, BlockBetween, BlockTitle, Row } from "../components/Component";
import { Button, Spinner } from "reactstrap";
import axios from "axios";
import toast from "react-hot-toast";

const AdminPages = () => {
  const [filterTab, setFilterTab] = useState("1");
  const editor = useRef(null);
  const [contentTerm, setContentTerm] = useState("");
  const [contentPrivacy, setContentPrivacy] = useState("");
  const [flag, setFlag] = useState(false);
  const [loader, setLoader] = useState(false);
  const config = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
    height: 400,
    toolbarSticky: false,
  };

  let newTermsData = "";
  let newPrivacyData = "";

  useEffect(() => {
    const getResponse = async () => {
      try {
        if (filterTab === "1") {
          const response = await axios.post(
            `${process.env.REACT_APP_SERVER_URL}/v1/admin/get-admin-settings`,
            {
              type: filterTab,
            },
            {
              headers: {
                token: localStorage.getItem("adminToken"),
              },
            }
          );

          setContentTerm(response.data.data.html_data);
        } else if (filterTab === "2") {
          const response = await axios.post(
            `${process.env.REACT_APP_SERVER_URL}/v1/admin/get-admin-settings`,
            {
              type: filterTab,
            },
            {
              headers: {
                token: localStorage.getItem("adminToken"),
              },
            }
          );

          setContentPrivacy(response.data.data.html_data);
        }
      } catch (err) {
        // console.log(`error ${err}`);
      }
    };
    getResponse();
  }, [filterTab, flag]);

  const updateTermsData = (newContent) => {
    newTermsData = newContent;
  };
  const updatePrivacydata = (newContent) => {
    newPrivacyData = newContent;
  };

  const updateTermsDataFn = async () => {
    try {
      setLoader(true);
      if (!newTermsData) {
        setLoader(false);
        toast.error("data not updated");
        return;
      } else {
        const response = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/v1/admin/update-admin-settings`,
          {
            type: 1,
            html_data: newTermsData,
          },
          {
            headers: {
              token: localStorage.getItem("adminToken"),
            },
          }
        );
        // console.log(response.data.status);
        if (response.data.status) {
          toast.success(response.data.message);
          setLoader(false);
        }
      }
    } catch (err) {
      // console.log(`error ${err}`);
      setLoader(false);
    }
    setFlag(!flag);
  };

  const updatePrivacydataFn = async () => {
    try {
      if (!newPrivacyData) {
        toast.error("data not updated");
        return;
      } else {
        const response = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/v1/admin/update-admin-settings`,
          {
            type: 2,
            html_data: newPrivacyData,
          },
          {
            headers: {
              token: localStorage.getItem("adminToken"),
            },
          }
        );
        // console.log(response.data.status);
        if (response.data.status) {
          toast.success(response.data.message);
        }
      }
    } catch (err) {
      // console.log(`error ${err}`);
    }
    setFlag(!flag);
  };

  return (
    <React.Fragment>
      <Head title="Admin Pages" />
      <section className="container-fluid">
        <BlockHead size="sm" className="mx-4">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>Pages</BlockTitle>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block className="mx-3">
          <Row className="">
            <div className="">
              <div className=" hide-aside">
                <div className="nk-msg-nav">
                  <ul className="nk-msg-menu">
                    <li
                      className={`nk-msg-menu-item  ${filterTab === "1" && " active"}`}
                      onClick={() => setFilterTab("1")}
                      style={{ listStyle: "none", cursor: "pointer" }}
                    >
                      <a>Term and Connections</a>
                    </li>
                    <li
                      className={`nk-msg-menu-item ${filterTab === "2" && " active"}`}
                      onClick={() => setFilterTab("2")}
                      style={{ listStyle: "none", cursor: "pointer" }}
                    >
                      <a>Privacy Policy</a>
                    </li>
                  </ul>
                </div>
                <div className="mt-5"></div>
                {filterTab == "1" ? (
                  <>
                    {" "}
                    <JoditEditor
                      ref={editor}
                      value={contentTerm ? contentTerm : "Loading..."}
                      config={config}
                      tabIndex={1} // tabIndex of textarea
                      // onBlur={(newContent) => setContentTerm(newContent)} // preferred to use only this option to update the content for performance reasons
                      onChange={(newContent) => {
                        updateTermsData(newContent);
                      }}
                    />
                    <div className="mt-3">
                      <Button className="w-10" onClick={updateTermsDataFn} disabled={loader}>
                        {loader ? <Spinner /> : "Send"}
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                    <JoditEditor
                      ref={editor}
                      value={contentPrivacy ? contentPrivacy : "loading..."}
                      config={config}
                      tabIndex={2} // tabIndex of textarea
                      onChange={(newContent) => {
                        updatePrivacydata(newContent);
                      }}
                    />
                    <div className="mt-3">
                      <Button className="w-10" onClick={updatePrivacydataFn} disabled={loader}>
                        {loader ? <Spinner /> : "Send"}
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </Row>
          <Row></Row>
        </Block>
      </section>
    </React.Fragment>
  );
};

export default AdminPages;
