import React, { useState, useLayoutEffect, useEffect } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";

import Home from "../pages/Home";
import TermsCondition from "../pages/TermsCondition";
import PrivacyPolicy from "../pages/PrivacyPolicy";

import Error404Classic from "../pages/error/404-classic";
import Error404Modern from "../pages/error/404-modern";
import Error504Modern from "../pages/error/504-modern";
import Error504Classic from "../pages/error/504-classic";

import Layout from "../layout/Index";
import LayoutNoSidebar from "../layout/Index-nosidebar";

import Workflows from "../pages/Workflows";
import AdminDashboard from "../pages/AdminDashboard";
import AdminUserListing from "../pages/AdminUserListing";
import AdminUserWorkflows from "../pages/AdminUserWorkflows";
import AdminUserWorkflowListing from "../pages/AdminUserWorkflowListing";
import AdminUserDetail from "../pages/AdminUserDetail";
import Connections from "../pages/Connections";
import YoutubeCallback from "../pages/YoutubeCallback";
import InstaCallback from "../pages/InstaCallback";
import TikTokCallback from "../pages/TikTokCallback";
import CreateWorkFlow from "../pages/CreateWorkFlow";
import ContentListing from "../pages/ContentListing";
import PostedVideosHistory from "../pages/PostedVideosHistory";
import ForgotPassId from "../pages/ForgotPassId";
import Login from "../pages/auth/Login";
import AdminLogin from "../pages/auth/AdminLogin";
import Register from "../pages/auth/Register";
import ForgotPassword from "../pages/auth/ForgotPassword";
import ResetPassword from "../pages/ResetPassword";
import Success from "../pages/auth/Success";
import AdminPages from "../pages/AdminPages";
import AdminDashboardSmtp from "../pages/AdminDashboardSmtp";

export const HomeRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<LayoutNoSidebar />}>
        <Route index element={<Home />}></Route>
        <Route path="/terms-conditions" element={<TermsCondition />}></Route>
        <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
        {/* <Route path="/*" element={<Navigate to="/" />}></Route> */}

        <Route path="*" element={<Error404Modern />}></Route>
      </Route>
    </Routes>
  );
};

export const AppRouter = () => {
  const [token, setToken] = useState(localStorage.getItem("accessToken"));
  const [fToken, setFToken] = useState(localStorage.getItem("forgotPassToken"));
  const location = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    setToken(localStorage.getItem("accessToken"));
    setFToken(localStorage.getItem("forgotPassToken"));
  }, []);

  return (
    <Routes>
      {token != "" && token != null ? (
        <Route path="/" element={<Layout />}>
          <Route index element={<Workflows />}></Route>
          <Route path="connections" element={<Connections />}></Route>
          <Route path="postedVideos" element={<PostedVideosHistory />}></Route>
          <Route path="youtube-callback" element={<YoutubeCallback />}></Route>
          <Route path="get-insta" element={<InstaCallback />}></Route>
          <Route path="tiktok-auth-handler" element={<TikTokCallback />}></Route>
          <Route path="createWorkFlow" element={<CreateWorkFlow />}></Route>
          <Route path="listing" element={<ContentListing />}></Route>
        </Route>
      ) : (
        <Route path="/" element={<LayoutNoSidebar />}>
          <Route path="/" element={<Navigate to="/login" />}></Route>
          <Route path="/*" element={<Navigate to="/login" />}></Route>
        </Route>
      )}

      {fToken != "" && fToken != null ? (
        <Route path="/" element={<LayoutNoSidebar />}>
          <Route path="reset-password" element={<ResetPassword />}></Route>
        </Route>
      ) : (
        <Route path="/" element={<LayoutNoSidebar />}>
          <Route path="reset-password" element={<Navigate to="/" />}></Route>
          <Route path="reset-password/*" element={<Navigate to="/" />}></Route>
        </Route>
      )}

      <Route path="/" element={<LayoutNoSidebar />}>
        <Route path="success" element={token != "" && token != null ? <Navigate to="/" /> : <Success />}></Route>
        <Route path="reset" element={<ForgotPassword />}></Route>
        <Route path="register" element={token != "" && token != null ? <Navigate to="/" /> : <Register />}></Route>
        <Route path="login" element={token != "" && token != null ? <Navigate to="/" /> : <Login />}></Route>
        <Route path="forgot-pass/:id" element={<ForgotPassId />}></Route>

        <Route path="errors">
          <Route path="404" element={<Error404Modern />}></Route>
          <Route path="404-classic" element={<Error404Classic />}></Route>
          <Route path="504-modern" element={<Error504Modern />}></Route>
          <Route path="504-classic" element={<Error504Classic />}></Route>
        </Route>
        <Route path="*" element={<Error404Modern />}></Route>
      </Route>
    </Routes>
  );
};

export const AdminRouter = () => {
  const [adminToken, setAdminToken] = useState(localStorage.getItem("adminToken"));
  const location = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    setAdminToken(localStorage.getItem("adminToken"));
  }, []);

  return (
    <Routes>
      {adminToken != "" && adminToken != null ? (
        <Route path="/" element={<Layout />}>
          <Route index element={<AdminDashboard />}></Route>
          <Route path="users" element={<AdminUserListing />}></Route>
          <Route path="user-workflows" element={<AdminUserWorkflows />}></Route>
          <Route path="userDetails/:id" element={<AdminUserDetail />}></Route>
          <Route path="user-workflow-listing" element={<AdminUserWorkflowListing />}></Route>
          <Route path="pages" element={<AdminPages />}></Route>
          <Route path="mail" element={<AdminDashboardSmtp />}></Route>
        </Route>
      ) : (
        <Route path="/" element={<LayoutNoSidebar />}>
          <Route path="/" element={<Navigate to="/login" />}></Route>
          <Route path="/*" element={<Navigate to="/login" />}></Route>
        </Route>
      )}

      <Route path="/" element={<LayoutNoSidebar />}>
        <Route
          path="login"
          element={adminToken != "" && adminToken != null ? <Navigate to="/" /> : <AdminLogin />}
        ></Route>

        <Route path="errors">
          <Route path="404" element={<Error404Modern />}></Route>
          <Route path="404-classic" element={<Error404Classic />}></Route>
          <Route path="504-modern" element={<Error504Modern />}></Route>
          <Route path="504-classic" element={<Error504Classic />}></Route>
        </Route>
        <Route path="*" element={<Error404Modern />}></Route>
      </Route>
    </Routes>
  );
};

export const LocalRouter = () => {
  const [token, setToken] = useState(localStorage.getItem("accessToken"));
  const [fToken, setFToken] = useState(localStorage.getItem("forgotPassToken"));
  const [adminToken, setAdminToken] = useState(localStorage.getItem("adminToken"));
  const location = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    setToken(localStorage.getItem("accessToken"));
    setFToken(localStorage.getItem("forgotPassToken"));
    setAdminToken(localStorage.getItem("adminToken"));
  }, []);

  return (
    <Routes>
      <Route path="/" element={<LayoutNoSidebar />}>
        <Route index element={<Home />}></Route>
        <Route path="/terms-conditions" element={<TermsCondition />}></Route>
        <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
        {/* <Route path="/*" element={<Navigate to="/" />}></Route> */}
        <Route path="*" element={<Error404Modern />}></Route>
      </Route>

      {adminToken != "" && adminToken != null ? (
        <Route path="/admin" element={<Layout />}>
          <Route index element={<AdminDashboard />}></Route>
          <Route path="users" element={<AdminUserListing />}></Route>
          <Route path="userDetails/:id" element={<AdminUserDetail />}></Route>
          <Route path="user-workflows" element={<AdminUserWorkflows />}></Route>
          <Route path="user-workflow-listing" element={<AdminUserWorkflowListing />}></Route>
          <Route path="pages" element={<AdminPages />}></Route>
          <Route path="mail" element={<AdminDashboardSmtp />}></Route>
        </Route>
      ) : (
        <Route path="/admin" element={<LayoutNoSidebar />}>
          <Route path="/admin" element={<Navigate to="/admin/login" />}></Route>
          <Route path="/admin/*" element={<Navigate to="/admin/login" />}></Route>
        </Route>
      )}

      {token != "" && token != null ? (
        <Route path="/app" element={<Layout />}>
          <Route index element={<Workflows />}></Route>
          <Route path="connections" element={<Connections />}></Route>
          <Route path="createWorkFlow" element={<CreateWorkFlow />}></Route>
          <Route path="listing" element={<ContentListing />}></Route>
          <Route path="postedVideos" element={<PostedVideosHistory />}></Route>
          <Route path="youtube-callback" element={<YoutubeCallback />}></Route>
        </Route>
      ) : (
        <Route path="/app" element={<LayoutNoSidebar />}>
          <Route path="/app" element={<Navigate to="/app/login" />}></Route>
          <Route path="/app/*" element={<Navigate to="/app/login" />}></Route>
        </Route>
      )}

      {fToken != "" && fToken != null ? (
        <Route path="/app" element={<LayoutNoSidebar />}>
          <Route path="reset-password" element={<ResetPassword />}></Route>
        </Route>
      ) : (
        <Route path="/app" element={<LayoutNoSidebar />}>
          <Route path="reset-password" element={<Navigate to="/" />}></Route>
          <Route path="reset-password/*" element={<Navigate to="/" />}></Route>
        </Route>
      )}

      <Route path="/admin" element={<LayoutNoSidebar />}>
        <Route
          path="login"
          element={adminToken != "" && adminToken != null ? <Navigate to="/" /> : <AdminLogin />}
        ></Route>

        <Route path="errors">
          <Route path="404" element={<Error404Modern />}></Route>
          <Route path="404-classic" element={<Error404Classic />}></Route>
          <Route path="504-modern" element={<Error504Modern />}></Route>
          <Route path="504-classic" element={<Error504Classic />}></Route>
        </Route>
        <Route path="*" element={<Error404Modern />}></Route>
      </Route>

      <Route path="/app" element={<LayoutNoSidebar />}>
        <Route path="success" element={token != "" && token != null ? <Navigate to="/" /> : <Success />}></Route>
        <Route path="reset" element={<ForgotPassword />}></Route>
        <Route path="register" element={token != "" && token != null ? <Navigate to="/" /> : <Register />}></Route>
        <Route path="login" element={token != "" && token != null ? <Navigate to="/" /> : <Login />}></Route>
        <Route path="forgot-pass/:id" element={<ForgotPassId />}></Route>

        <Route path="errors">
          <Route path="404" element={<Error404Modern />}></Route>
          <Route path="404-classic" element={<Error404Classic />}></Route>
          <Route path="504-modern" element={<Error504Modern />}></Route>
          <Route path="504-classic" element={<Error504Classic />}></Route>
        </Route>
        <Route path="*" element={<Error404Modern />}></Route>
      </Route>
    </Routes>
  );
};
