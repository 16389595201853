import React, { useState, useEffect } from "react";
import Head from "../layout/head/Head";
import videoIcon from "../images/video-icon.png";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockBetween,
  BlockTitle,
  Icon,
  PreviewCard,
  ReactDataTable,
  Button,
  Row,
} from "../components/Component";
import CheckSucess from "../assets/images/sucess_check.png";
import checkFailure from "../assets/images/check_failure.png";
import Accordion from "./Accordian/AdminAccordian";
import { Spinner } from "reactstrap";
import instaIcon from "../images/Insta-icon.png";
import youtubeIcon from "../images/Youtube-icon.png";
import tiktokIcon from "../images/TikTok-icon.png";
import moment from "moment/moment";
import { useNavigate, useParams } from "react-router";
import axios from "axios";
const AdminUserDetail = () => {
  const [userDetails, setUserDetails] = useState({});
  const [loader, setLoader] = useState(false);
  const [instaConnection, setInstaConnection] = useState(false);
  const [tiktokConnection, setTiktokConnection] = useState(false);
  const [youtubeConnection, setyoutubeConnection] = useState(false);
  const [workflowData, setWorkflowData] = useState([]);
  const [contentData, setContentData] = useState([]);
  const [rowsPerPageS, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowCount, setRowCount] = useState(1);
  const params = useParams();
  const { id } = params;

  const getResponse = async () => {
    setLoader(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/v1/admin/get-user-details`,
        {
          user_id: id,
          offset: Number(currentPage),
          limit: Number(rowsPerPageS),
        },
        {
          headers: {
            token: localStorage.getItem("adminToken"),
          },
        }
      );
      setLoader(false);
      // console.log(response.data.data.totalRowData);
      setRowCount(response.data.data.totalRowData);
      setUserDetails(response.data.data.user_details);
      setWorkflowData(response.data.data.user_workflow_details);
      setContentData(response.data.data.history_details);
      for (let index = 0; index < response.data.data.user_connection_details.length; index++) {
        if (response.data.data.user_connection_details[index].platform_id == "1") {
          setInstaConnection(true);
        } else if (response.data.data.user_connection_details[index].platform_id == "2") {
          setTiktokConnection(true);
        } else if (response.data.data.user_connection_details[index].platform_id == "3") {
          setyoutubeConnection(true);
        }
      }
    } catch (err) {
      // console.log(err);
      setLoader(false);
    }
  };
  const navigate = useNavigate();

  useEffect(() => {
    getResponse();
  }, [rowsPerPageS, currentPage]);

  const dataTableColumns = [
    {
      name: "Media",
      // selector: (row) => row.thumbnailUrl,
      // sortable: true,
      style: { minHeight: "100px" },
      cell: (row) =>
        row.jsondata?.media_url ? (
          <a href={row.jsondata.permalink ? row.jsondata.permalink : row.jsondata?.media_url} target="_blank">
            <img className="video-thumbnail" src={videoIcon} alt="Media-Thumbnail" />
          </a>
        ) : (
          <a href={`https://www.youtube.com/watch?v=${row.jsondata?.media_id}`} target="_blank">
            <img className="video-thumbnail" src={videoIcon} alt="Media-Thumbnail" />
          </a>
        ),
    },
    {
      name: "Source",
      selector: (row) => (row.source_id == 1 ? "Instagram" : row.source_id == 2 ? "TikTok" : "Youtube"),
      // sortable: true,
      // hide: 370,
    },
    {
      name: "Destination",
      selector: (row) => (row.destination_id == 1 ? "Instagram" : row.destination_id == 2 ? "TikTok" : "Youtube"),
      // sortable: true,
      // hide: 370,
    },
    {
      name: "Date",
      selector: (row) => moment(row.updated_at).format("MM-DD-YYYY"),
      // sortable: true,
      // hide: 370,
    },
    {
      name: "Time",
      selector: (row) => moment(row.updated_at).format("LT"),
      // sortable: true,
      // hide: 370,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      // sortable: true,
      // hide: "sm",
      style: { paddingLeft: "4px" },
      cell: (row) => (
        <span
          className={`tb-status ms-1 text-${
            row.status == 2 ? "success" : row.status == 1 ? (row.run_task_count == 4 ? "danger" : "warning") : "danger"
          }`}
        >
          {row.status == 2
            ? row.republished == 1
              ? "Success(Re-Published)"
              : "Success"
            : row.status == 1
            ? row.run_task_count == 4
              ? "Error(Re-Published)"
              : "In Process"
            : row.status == 3
            ? "In Process"
            : row.status == 5 && row.republished == 1
            ? "Error(Re-Published)"
            : "Error"}
        </span>
      ),
    },
  ];
  // console.log(dataTableColumns);
  return (
    <React.Fragment>
      <Head title="User-details" />
      {!loader ? (
        <>
          <section className="container">
            <BlockHead size="sm" className="ms-4">
              <BlockBetween>
                <BlockHeadContent>
                  <BlockTitle page className="text-capitalize">
                    User : {userDetails.full_name}
                  </BlockTitle>
                </BlockHeadContent>
                <BlockHeadContent></BlockHeadContent>
              </BlockBetween>
            </BlockHead>
            <Button
              onClick={() => {
                localStorage.removeItem("userId");
                navigate("/users");
              }}
              className="btn-dim btn-white btn-outline-light me-5 mb-4 ms-4"
            >
              <Icon name="arrow-left-fill-c" />
              <div className="back-btn">Back</div>
            </Button>
            <PreviewCard className="mt-5">
              <Block>
                <BlockHead>
                  <BlockTitle tag="h5">Personal Information</BlockTitle>
                </BlockHead>
                <div className="profile-ud-list">
                  <div className="profile-ud-item">
                    <div className="profile-ud wider">
                      <span className="profile-ud-label">Name</span>
                      <span className="profile-ud-value text-capitalize text-start">{userDetails.full_name}</span>
                    </div>
                  </div>
                  {/* <div className="profile-ud-item">
                  <div className="profile-ud wider">
                    <span className="profile-ud-label">Mobile Number</span>
                    <span className="profile-ud-value">
                      {userDetails.phone_number ? userDetails.phone_number : "-"}
                    </span>
                  </div>
                </div> */}
                  <div className=" ">
                    <div className="responsive_width">
                      <span className="margin_left">Connection</span>
                      {/* <span className="profile-ud-value">{userDetails.country_code ? userDetails.country_code : "-"}</span> */}
                      <span className="" style={{ width: "50px" }}>
                        <img className="h-2rem ms-5" src={instaIcon} />
                      </span>
                      <span className="">
                        {" "}
                        {instaConnection ? (
                          <img className="h-1rem ms-2" src={CheckSucess} alt="sucess" />
                        ) : (
                          <img className="h-1rem ms-2" src={checkFailure} alt="failure" />
                        )}
                      </span>
                      <span className=" ms-4" style={{ width: "50px" }}>
                        <img className="h-2rem" src={tiktokIcon} />
                      </span>
                      <span className=" ">
                        {" "}
                        {tiktokConnection ? (
                          <img className="h-1rem ms-2" src={CheckSucess} alt="sucess" />
                        ) : (
                          <img className="h-1rem ms-2" src={checkFailure} alt="failure" />
                        )}
                      </span>
                      <span className="ms-4" style={{ width: "60px" }}>
                        <img className="h-2rem" src={youtubeIcon} />
                      </span>
                      <span className=" ">
                        {" "}
                        {youtubeConnection ? (
                          <img className="h-1rem ms-2" src={CheckSucess} alt="sucess" />
                        ) : (
                          <img className="h-1rem ms-2" src={checkFailure} alt="failure" />
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="profile-ud-item">
                    <div className="profile-ud wider">
                      <span className="profile-ud-label">Email</span>
                      <span className="profile-ud-value text-start">{userDetails.email ? userDetails.email : "-"}</span>
                    </div>
                  </div>
                  {/* <div className="profile-ud-item">
                  <div className="profile-ud wider">
                    <span className="profile-ud-label">Date of Birth</span>
                    <span className="profile-ud-value">{userDetails.dob_date ? userDetails.dob_date : "-"}</span>
                  </div>
                </div> */}
                </div>
              </Block>
            </PreviewCard>

            <PreviewCard className="mt-5">
              <Block className="">
                <BlockHead>
                  <BlockTitle tag="h5">WorkFlows</BlockTitle>
                </BlockHead>
                <Row>
                  {workflowData.length > 0 ? (
                    workflowData.map((data, index) => <Accordion key={index} data={data} setLoader={setLoader} />)
                  ) : loader ? (
                    <div className="d-flex justify-content-center mt-4">
                      <Spinner color="primary" />
                    </div>
                  ) : (
                    <h4 className="text-center">No workflow found. </h4>
                  )}
                </Row>
              </Block>
            </PreviewCard>
            {loader ? (
              <div className="d-flex justify-content-center mt-4">
                <Spinner color="primary" />
              </div>
            ) : (
              <PreviewCard className="mt-5">
                {/* <section className=""> */}
                <Block>
                  <BlockHead className="">
                    <BlockTitle tag="h5">History</BlockTitle>
                  </BlockHead>

                  <Row>
                    <ReactDataTable
                      data={contentData}
                      columns={dataTableColumns}
                      setRowsPerPage={setRowsPerPage}
                      rowsPerPageS={rowsPerPageS}
                      setCurrentPage={setCurrentPage}
                      currentPage={currentPage}
                      rowCount={rowCount}
                      pagination
                      className="nk-tb-list"
                    />
                  </Row>
                  {/* </section> */}
                </Block>
              </PreviewCard>
            )}
          </section>
        </>
      ) : (
        <div className="loader-container">
          <div className="loader-wrapper">
            <Spinner className="animate-spin text-center text-blue-800" />
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default AdminUserDetail;
