import React from "react";
import { Col, Container, Row } from "reactstrap";
import group4 from "../../assets/LandingPage/group4.png";

const HomeComp4 = () => {
  const navigate = () => {
    window.location.href = `${process.env.REACT_APP_PUBLIC_SUBDOMAIN_URL}/login`;
  };
  return (
    <div className="set_padding_top_seprate">
      <Container>
        <div className="row m-0">
          <div className="home_block_description col-lg-6 col-sm-12 pb-4 d-flex align-items-center">
            <div className="mb-4 component-responsive">
              <div className="home_comp_green responsive-pt">Never miss a post</div>
              <div className="home_comp_para">
                No longer spend time posting on each platform one at a time. All you need to do it post once and be
                done!
              </div>
              <div>
                <button className="get_started_btn_black" onClick={navigate}>
                  Get Started
                </button>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-sm-12 ps-lg-5">
            <img src={group4} alt="group-image" />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default HomeComp4;
