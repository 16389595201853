import React, { useState, useEffect } from "react";
import classNames from "classnames";
import Toggle from "../sidebar/Toggle";
import Logo from "../logo/Logo";
import User from "./dropdown/user/User";
import toast, { Toaster } from "react-hot-toast";
import Notification from "./dropdown/notification/Notification";

import { useTheme, useThemeUpdate } from "../provider/Theme";

const Header = ({ fixed, className, ...props }) => {
  const [sticky, setSticky] = useState(false);

  const theme = useTheme();
  const themeUpdate = useThemeUpdate();

  const headerClass = classNames({
    "nk-header": true,
    "nk-header-fixed": fixed,
    "has-fixed": sticky,
    [`is-light`]: theme.header === "white",
    [`is-${theme.header}`]: theme.header !== "white" && theme.header !== "light",
    [`${className}`]: className,
  });

  document.addEventListener("scroll", () => {
    let _item_offset = 30;
    if (window.scrollY > _item_offset) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  });

  // useEffect(() => {
  //   let _item_offset = 30;
  //   console.log(window.scrollY);
  //   if (window.scrollY > _item_offset) {
  //     setSticky(true);
  //   } else {
  //     setSticky(false);
  //   }
  // }, [window.scrollY]);

  return (
    <React.Fragment>
      <Toaster
        toastOptions={{
          className: "",
          style: {
            border: "1px solid #713200",
            padding: "16px",
            color: "#713200",
          },
        }}
      />

      <div className={headerClass}>
        <div className="container-fluid">
          <div className="nk-header-wrap">
            <div className="nk-menu-trigger d-xl-none ms-n1">
              <Toggle
                className="nk-nav-toggle nk-quick-nav-icon d-xl-none ms-n1 me-3"
                icon="menu"
                click={themeUpdate.sidebarVisibility}
              />
            </div>
            <div className="nk-header-brand d-xl-none">
              <Logo />
            </div>
            <div className="nk-header-tools">
              <ul className="nk-quick-nav">
                {/* <li className="notification-dropdown me-n1">
                <Notification />
              </li> */}
                <li className="user-dropdown">
                  <User />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Header;
