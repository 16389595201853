import { HomeRouter, AppRouter, AdminRouter, LocalRouter } from "./route/Index";
import "./app.css";
import "./styles.css";

import ThemeProvider from "./layout/provider/Theme";
import { useState } from "react";

const App = () => {
  const [subDomain, setSubDomain] = useState(window.location.hostname.split(".")[0]);

  return (
    <ThemeProvider>
      {subDomain == "localhost" ? (
        <LocalRouter />
      ) : subDomain == "app" ? (
        <AppRouter />
      ) : subDomain == "admin" ? (
        <AdminRouter />
      ) : (
        <HomeRouter />
      )}
    </ThemeProvider>
  );
};
export default App;
