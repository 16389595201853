import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Col,
  Collapse,
  Row,
  Modal,
  ModalBody,
  Button,
} from "reactstrap";
import instaIcon from "../../images/instagram-icon.png";
import youtubeIcon from "../../images/Youtube-icon.png";
import tiktokIcon from "../../images/TikTok-icon.png";
import { Icon } from "../../components/Component";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const Accordion = ({ data, setLoader, className, variation, ...props }) => {
  const [isOpen, setIsOpen] = useState("1");
  const [dropdown, setDropdown] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [displayText, setDisplayText] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    if (windowWidth > 991) {
      if (data?.name.length > 60) {
        setDisplayText(data.name.substring(0, 60) + "...");
      } else {
        setDisplayText(data.name);
      }
    } else {
      setDisplayText(data.name.substring(0, 20) + (data.name.length > 20 ? "..." : ""));
    }
  }, [windowWidth, data]);

  const toggle = () => {
    setDropdown(!dropdown);
  };

  const toggleCollapse = (param) => {
    if (param === isOpen) {
      setIsOpen("0");
    } else {
      setIsOpen(param);
    }
  };

  const deleteModalFunction = () => setDeleteModal(!deleteModal);

  const deleteConnection = async () => {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/v1/workflow/delete-workflow`,
      {
        flow_id: data.id,
        user_id: data.user_id,
        source_id: data.source_id,
        destination_id: data.destination_id,
      },
      {
        headers: {
          token: localStorage.getItem("accessToken"),
        },
      }
    );
    if (response.data.status) {
      deleteModalFunction();
      toast.success(response.data.message);
      setTimeout(() => {
        window.location.reload();
      }, 100);
    } else {
      deleteModalFunction();
      toast.error(response.data.message);
    }
  };

  const showDropDownFn = () => {
    if (window.screen.width <= 576) {
      setShowDropdown(true);
    } else {
      if (showDropdown) setShowDropdown(false);
    }
  };

  window.addEventListener("resize", showDropDownFn);

  return (
    <Fragment>
      <div className="d-flex">
        <div
          className={[
            `col-11 accordion${variation ? " accordion-s" + variation : ""}${className ? " " + className : ""}`,
          ]}
        >
          <div className="accordion-item">
            <div
              className={[`accordion-head${isOpen !== "1" ? " collapsed" : ""}`]}
              onClick={() => toggleCollapse("1")}
            >
              <div className="d-flex align-items-center justify-content-between">
                <h4>{displayText}</h4>
                <div className="content-show-btn">
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      localStorage.setItem("source_id", data.source_id);
                      localStorage.setItem("destination_id", data.destination_id);
                      navigate("/listing");
                    }}
                    className="me-5 btn-dim btn-white btn-outline-light all-content-btn"
                  >
                    All Content
                  </Button>
                </div>
              </div>
              <span className="accordion-icon"></span>
            </div>
            <Collapse className="accordion-body" isOpen={isOpen === "1" ? true : false}>
              <div className="accordion-inner">
                <div className="d-flex align-items-center">
                  <img
                    className="iconView"
                    src={data.source_id == 1 ? instaIcon : data.source_id == 2 ? tiktokIcon : youtubeIcon}
                    alt="Source Icon"
                  />
                  <h6 className="title ms-2 fs-3">
                    {data.source_id == 1 ? "Instagram" : data.source_id == 2 ? "TikTok" : "Youtube"}
                  </h6>
                </div>
                <Row className="destination-div">
                  <Col lg={1} md={2} sm={2} xs={2}>
                    <div className="border-style"></div>
                  </Col>
                  <Col lg={11} md={10} sm={10} xs={10} className="destination-connection">
                    <div className="d-flex pt-3 pb-3">
                      <img
                        className="iconView"
                        src={data.destination_id == 1 ? instaIcon : data.destination_id == 2 ? tiktokIcon : youtubeIcon}
                        alt="Destination Icon"
                      />
                      <div className="d-flex flex-column justify-content-center ps-3">
                        <h6 className="fs-4 platform-name">
                          {data.destination_id == 1 ? "Instagram" : data.destination_id == 2 ? "TikTok" : "Youtube"}
                        </h6>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Collapse>
          </div>
        </div>
        <Dropdown isOpen={dropdown} toggle={toggle} className="d-block pt-3 dropdown-box">
          <DropdownToggle className="btn-action options-btn" color="primary">
            <Icon name="more-v" className="btn-color"></Icon>
          </DropdownToggle>
          <DropdownMenu className="dropdown-position">
            <ul className="link-list-opt">
              <li>
                <DropdownItem
                  onClick={() => {
                    deleteModalFunction(true);
                  }}
                >
                  <span>Delete</span>
                </DropdownItem>
              </li>
              {showDropdown ? (
                <li>
                  <DropdownItem
                    onClick={(e) => {
                      e.stopPropagation();
                      localStorage.setItem("source_id", data.source_id);
                      localStorage.setItem("destination_id", data.destination_id);
                      navigate("/listing");
                    }}
                  >
                    <span>All Content</span>
                  </DropdownItem>
                </li>
              ) : (
                ""
              )}
            </ul>
          </DropdownMenu>
        </Dropdown>
      </div>

      <Modal size="lg" isOpen={deleteModal} toggle={deleteModalFunction}>
        <ModalBody className="connection-modal">
          <div className="mb-3 modal-head">
            <button className="close modal-close" onClick={deleteModalFunction}>
              <Icon name="cross" />
            </button>
            <div className="d-flex justify-content-center fw-bolder fs-20px text-center mx-4">
              Deleting this workflow will delete all it's related information. Are you sure you want to delete this
              workflow?
            </div>
          </div>
          <div className="d-flex justify-content-around">
            <Button
              className="col-5 d-block"
              onClick={() => {
                deleteModalFunction();
              }}
            >
              No
            </Button>
            <Button
              className="col-5 d-block text-bg-primary"
              onClick={() => {
                setLoader(true);
                deleteConnection();
              }}
            >
              Yes
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default Accordion;
