import React, { useEffect } from "react";
import Head from "../layout/head/Head";
import { Block, BlockHead, BlockHeadContent, BlockBetween, BlockTitle } from "../components/Component";
import comingSoon from "../images/coming-soon.jpg";
import HomeComp1 from "./home_landing_components/HomeComp1";
import HomeComp2 from "./home_landing_components/HomeComp2";
import HomeComp3 from "./home_landing_components/HomeComp3";
import HomeComp4 from "./home_landing_components/HomeComp4";
import HomeComp5 from "./home_landing_components/HomeComp5";
import Footer from "./home_landing_components/Footer";

const Home = () => {
  // useEffect(() => {
  //   if (localStorage.getItem("forgotPassToken")) {
  //     localStorage.removeItem("forgotPassToken");
  //   }
  // }, []);
  return (
    <React.Fragment>
      <Head title="Home" />
      <HomeComp1 />
      <HomeComp2 />
      <HomeComp3 />
      <HomeComp4 />
      <HomeComp5 />
      <Footer />
    </React.Fragment>
  );
};

export default Home;
