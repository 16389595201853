import React, { useEffect, useState } from "react";
import axios from "axios";
import { Spinner } from "reactstrap";
import { RSelect, Button, Icon } from "../components/Component";
import { useNavigate } from "react-router";
import { useOutletContext } from "react-router-dom";

const ChooseSourceType = ({ setTypes, setSourceType, sourceType, sourceVideoType, setSourceVideoType }) => {
  const [modalLg, setModalLg] = useOutletContext();
  const [loader, setLoader] = useState(false);
  const [isConnection, setIsConnection] = useState(false);
  const navigate = useNavigate();
  const [options, setOptions] = useState([
    { id: 1, value: 1, label: "Instagram", disabled: true },
    { id: 2, value: 2, label: "TikTok", disabled: true },
    { id: 3, value: 3, label: "Youtube", disabled: true },
  ]);

  useEffect(() => {
    setLoader(true);
    getAllConnections();
  }, []);

  const getAllConnections = async () => {
    const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/v1/users/get-connections`, {
      headers: {
        token: localStorage.getItem("accessToken"),
      },
    });
    // console.log("response: ", response);
    if (response.data.data.length > 0) {
      response.data.data.map((el) => {
        options.map((data) => {
          if (data.value == el.platform_id) {
            data.disabled = false;
          }
        });
      });
      setOptions([...options]);
      setIsConnection(true);
    }
    setLoader(false);
  };

  const forwardFn = (e) => {
    e.preventDefault();
    setTypes(2);
  };

  const backFn = (e) => {
    e.preventDefault();
    setSourceType("");
    setSourceVideoType("");
    setTypes(0);
  };

  const vOptions = [
    { id: 1, value: "Reels", label: "Reels" },
    // { id: 2, value: "Youtube", label: "Youtube" },
    // { id: 3, value: "TikTok", label: "TikTok" },
  ];
  const navigateToConnectionFn = () => {
    navigate("/connections");
    setModalLg(true);
  };

  return (
    <div className="source-type-div">
      {loader ? (
        <div className="d-flex justify-content-center mt-4">
          <Spinner color="primary" />
        </div>
      ) : !isConnection ? (
        <div className="d-flex flex-column justify-content-center align-center">
          <h4 className="text-center pt-5 mt-5">No connections found. Please add a new connection.</h4>
          <Button className=" btn-dim btn-white btn-outline-light " onClick={navigateToConnectionFn}>
            <Icon name="plus"></Icon>
            <span>
              {/* <span className="d-md-none">Add</span> */}
              <span className="d-block" style={{ fontSize: "14px" }}>
                ADD A NEW CONNECTION
              </span>
            </span>
          </Button>
        </div>
      ) : (
        <React.Fragment>
          <p className="set_paraTag_source">Choose a Source Type</p>
          <RSelect
            options={options}
            onChange={(e) => setSourceType(e)}
            value={sourceType}
            isOptionDisabled={(option) => option.disabled}
          />
          {/* {sourceType != "" ? (
            <>
              <p className="set_paraTag_source">Choose a Source Video Type</p>
              <RSelect options={vOptions} onChange={(e) => setSourceVideoType(e)} value={sourceVideoType} />
            </>
          ) : (
            ""
          )} */}
          {sourceType != "" ? (
            <div className="text-center">
              <Button onClick={backFn} size="lg" className="mt-4   me-2 btn_back justify-content-center" color="">
                BACK
              </Button>
              <Button onClick={forwardFn} size="lg" className="mt-4" color="primary">
                NEXT
              </Button>
            </div>
          ) : (
            ""
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default ChooseSourceType;
