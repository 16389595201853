export const menu = [
  { heading: "User Dashboard" },
  {
    icon: "repeat-fill",
    text: "Workflows",
    link: "/",
  },
  {
    icon: "network",
    text: "Connections",
    link: "/connections",
  },
  {
    icon: "history",
    text: "Posted Videos",
    link: "/postedVideos",
  },
];

export const adminMenu = [
  { heading: "Admin Dashboard" },
  {
    icon: "dashboard-fill",
    text: "Dashboard",
    link: "/",
  },
  {
    icon: "user-list-fill",
    text: "Users",
    link: "/users",
  },
  {
    icon: "todo",
    text: "Pages",
    link: "/pages",
  },

  {
    icon: "account-setting-fill",
    text: "SMTP Settings",
    link: "/mail",
  },
];
