import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import Head from "../layout/head/Head";
import { useNavigate } from "react-router-dom";
import instaIcon from "../images/instagram-icon.png";
import youtubeIcon from "../images/Youtube-icon.png";
import tiktokIcon from "../images/TikTok-icon.png";
import videoIcon from "../images/video-icon.png";
import { Col, Row, Spinner } from "reactstrap";
import {
  BlockHead,
  BlockHeadContent,
  BlockBetween,
  BlockTitle,
  Icon,
  PreviewCard,
  ReactDataTable,
  Button,
} from "../components/Component";
import toast from "react-hot-toast";
import moment from "moment/moment";

function AdminUserWorkflowListing() {
  const [contentData, setContentData] = useState([]);
  const [rowsPerPageS, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState({});
  const [rowCount, setRowCount] = useState(1);

  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("userId") && localStorage.getItem("source_id") && localStorage.getItem("destination_id")) {
      getAllVideoData();
    } else {
      navigate("/");
    }
  }, [currentPage, rowsPerPageS]);

  const getAllVideoData = async () => {
    setLoader(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/v1/admin/get-all-user-workflow-listing`,
        {
          user_id: localStorage.getItem("userId"),
          source_id: localStorage.getItem("source_id"),
          destination_id: localStorage.getItem("destination_id"),
          offset: Number(currentPage),
          limit: Number(rowsPerPageS),
        },
        {
          headers: {
            token: localStorage.getItem("adminToken"),
          },
        }
      );
      if (response.data.status) {
        toast.success(response.data.message);
        setTimeout(() => {
          setData(response.data);
          setContentData(response.data.data.workflowListing);
          setRowCount(response.data.data.totalRowData);

          setLoader(false);
        }, 1000);
      } else {
        toast.success(response.data.message);
        setTimeout(() => {
          setData(response.data);
          setContentData(response.data.data);
          setLoader(false);
        }, 1000);
      }
    } catch (err) {
      setLoader(false);
      toast.error(err.message);
    }
  };

  const dataTableColumns = [
    {
      name: "Media",
      // selector: (row) => row.thumbnailUrl,
      // sortable: true,
      style: { minHeight: "100px" },
      cell: (row) =>
        row.jsondata.media_url ? (
          <a href={row.jsondata.permalink ? row.jsondata.permalink : row.jsondata.media_url} target="_blank">
            <img className="video-thumbnail" src={videoIcon} alt="Media-Thumbnail" />
          </a>
        ) : (
          <a href={`https://www.youtube.com/watch?v=${row.jsondata.media_id}`} target="_blank">
            <img className="video-thumbnail" src={videoIcon} alt="Media-Thumbnail" />
          </a>
        ),
    },
    {
      name: "Date",

      style: { width: "200px" },
      selector: (row) => moment(row.updated_at).format("MM-DD-YYYY"),
      // sortable: true,
      // hide: 370,
    },
    {
      name: "Time",
      selector: (row) => moment(row.updated_at).format("LT"),
      // sortable: true,
      // hide: 370,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      // sortable: true,
      // hide: "sm",
      style: { paddingLeft: "4px" },
      cell: (row) => (
        <span
          className={`tb-status ms-1 text-${
            row.status == 2 ? "success" : row.status == 1 ? (row.run_task_count == 4 ? "danger" : "warning") : "danger"
          }`}
        >
          {row.status == 2
            ? row.republished == 1
              ? "Success(Re-Published)"
              : "Success"
            : row.status == 1
            ? row.run_task_count == 4
              ? "Error(Re-Published)"
              : "In Process"
            : row.status == 3
            ? "In Process"
            : row.status == 5 && row.republished == 1
            ? "Error(Re-Published)"
            : "Error"}
        </span>
      ),
    },
    // {
    //   name: "Action",
    //   // selector: (row) => row.action,
    //   // sortable: true,
    //   // hide: "sm",
    //   cell: (row) =>
    //     (row.status == 4 || row.run_task_count == 4) &&
    //     row.status != 5 &&
    //     !moment(row.republished_time).add(24, "h").isBefore(moment()) ? (
    //       <Button onClick={() => videoPublish(row.id)} className="btn-dim" color="primary">
    //         Republish
    //       </Button>
    //     ) : (
    //       ""
    //     ),
    // },
  ];

  return (
    <Fragment>
      <Head title="Video Listing" />
      {loader ? (
        <div className="d-flex justify-content-center mt-4">
          <Spinner color="primary" />
        </div>
      ) : (
        <section className="container">
          <BlockHead size="sm" className="mb-4">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle page>User Workflow Listing</BlockTitle>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <Button
            onClick={() => {
              localStorage.removeItem("source_id");
              localStorage.removeItem("destination_id");
              navigate(-1);
            }}
            className="btn-dim btn-white btn-outline-light me-5"
          >
            <Icon name="arrow-left-fill-c" />
            <div className="back-btn">Back</div>
          </Button>

          <PreviewCard className="mt-5">
            <div className="d-flex align-items-center header-listing">
              <div className="d-flex align-items-center">
                <img
                  className="lt-icon"
                  src={data.source_id == 1 ? instaIcon : data.source_id == 2 ? tiktokIcon : youtubeIcon}
                  alt="Insta-icon"
                />
                <h5 className="ms-2">
                  {data.source_id == 1 ? "Instagram" : data.source_id == 2 ? "TikTok" : "Youtube"}
                </h5>
              </div>
              <Col className="arrow-col" lg={1} md={1} sm={1} xs={1}>
                <Row className="arrow-design">
                  <Icon className="forward-arrow" name="forward-alt-fill" />
                </Row>
                <Row></Row>
              </Col>
              <div className="d-flex align-items-center">
                <img
                  className="lt-icon"
                  src={data.destination_id == 1 ? instaIcon : data.destination_id == 2 ? tiktokIcon : youtubeIcon}
                  alt="TikTok-icon"
                />
                <h5 className="ms-2">
                  {data.destination_id == 1 ? "Instagram" : data.destination_id == 2 ? "TikTok" : "Youtube"}
                </h5>
              </div>
            </div>
            <ReactDataTable
              data={contentData}
              columns={dataTableColumns}
              setRowsPerPage={setRowsPerPage}
              rowsPerPageS={rowsPerPageS}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              rowCount={rowCount}
              pagination
              className="nk-tb-list"
            />
          </PreviewCard>
        </section>
      )}
    </Fragment>
  );
}

export default AdminUserWorkflowListing;
