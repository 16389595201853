import React, { useState } from "react";
import axios from "axios";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Button,
  Icon,
  PreviewCard,
} from "../../components/Component";
import { Spinner } from "reactstrap";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { name } from "../../utils/common";
import googleIcon from "../../images/google-icon.png";
import toast from "react-hot-toast";

const Register = () => {
  const [passState, setPassState] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();

  const handleFormSubmit = (formData) => {
    document.getElementById("email").disabled = true;
    document.getElementById("name").disabled = true;
    document.getElementById("password").disabled = true;
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}/v1/users/signup`, {
        email: formData.email.toLowerCase(),
        user_name: formData.name,
        password: formData.passcode,
      })
      .then((res) => {
        if (res.data.status) {
          navigate(`/success`);
        } else {
          document.getElementById("email").disabled = false;
          document.getElementById("name").disabled = false;
          document.getElementById("password").disabled = false;
          setLoading(false);
          toast.error(res.data.message);
        }
      })
      .catch((response) => {
        document.getElementById("email").disabled = false;
        document.getElementById("name").disabled = false;
        document.getElementById("password").disabled = false;
        setLoading(false);
        // console.log("response: ", response);
        toast.error(`Server Error. Please try again later. ${response.message}`);
      });
  };

  const navigateHome = () => {
    window.location.href = `${process.env.REACT_APP_PUBLIC_URL}/`;
  };

  return (
    <>
      <Head title="Register" />
      <Block className="nk-block-middle nk-auth-body  wide-xs">
        <div className="brand-logo mb-4 text-center fw-bold" onClick={navigateHome}>
          {name}
          {/* <Link to={`${process.env.PUBLIC_URL}/`} className="logo-link"> */}
          {/* <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
            <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" /> */}
          {/* </Link> */}
        </div>
        <PreviewCard className="" bodyClass="card-inner-lg">
          <BlockHead>
            <BlockContent>
              <BlockTitle tag="h4">Register</BlockTitle>
              <BlockDes>
                <p>Create New {name} Account</p>
              </BlockDes>
            </BlockContent>
          </BlockHead>
          <form className="is-alter" onSubmit={handleSubmit(handleFormSubmit)}>
            <div className="form-group">
              <div className="form-label-group">
                <label className="form-label" htmlFor="email">
                  Email
                </label>
              </div>
              <div className="form-control-wrap">
                <input
                  type="text"
                  bssize="lg"
                  id="email"
                  {...register("email", {
                    required: true,
                    pattern: {
                      value: /^[a-zA-Z0-9_.±]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/,
                    },
                  })}
                  className="form-control-lg form-control"
                  placeholder="Enter your email address"
                />
                {errors.email && <p className="invalid">Please enter a valid email address.</p>}
              </div>
            </div>
            <div className="form-group">
              <label className="form-label" htmlFor="name">
                Full Name
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="name"
                  {...register("name", {
                    required: true,
                    minLength: 1,
                    maxLength: 60,
                    pattern: {
                      value: /^[A-Za-z]+(?:\s+[A-Za-z]+)*\s+[A-Za-z]+$/,
                    },
                  })}
                  placeholder="Enter your full name"
                  className="form-control-lg form-control"
                />
                {errors.name && (
                  <p className="invalid">
                    Please enter your full name and you shouldn't enter more than 60 characters.
                  </p>
                )}
              </div>
            </div>
            <div className="form-group">
              <div className="form-label-group">
                <label className="form-label" htmlFor="password">
                  Password
                </label>
              </div>
              <div className="form-control-wrap">
                <a
                  href="#password"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setPassState(!passState);
                  }}
                  className={`form-icon lg form-icon-right passcode-switch ${passState ? "is-hidden" : "is-shown"}`}
                >
                  <Icon name="eye" className="passcode-icon icon-show"></Icon>

                  <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                </a>
                <input
                  type={passState ? "text" : "password"}
                  id="password"
                  {...register("passcode", {
                    required: true,
                    pattern: { value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{5,}$/ },
                  })}
                  placeholder="Enter your password"
                  className={`form-control-lg form-control ${passState ? "is-hidden" : "is-shown"}`}
                />
                {errors.passcode && (
                  <span className="invalid">
                    Password should contain at least one uppercase letter, one lowercase letter, one number, one special
                    character and must be a minimum length of 5 characters
                  </span>
                )}
              </div>
            </div>
            <div className="form-group">
              <Button type="submit" color="primary" size="lg" className="btn-block">
                {loading ? <Spinner size="sm" color="light" /> : "Register"}
              </Button>
            </div>
          </form>
          <div className="form-note-s2 text-center pt-4">
            {" "}
            Already have an account?{" "}
            <Link to={`${process.env.REACT_APP_PUBLIC_SUBDOMAIN_URL}/login`}>
              <strong>Sign in instead</strong>
            </Link>
          </div>
          {/* <div className="text-center pt-4 pb-3">
            <h6 className="overline-title overline-title-sap">
              <span>OR</span>
            </h6>
          </div>
          <ul className="nav justify-center gx-8 m-0">
            <li className="nav-item google-item">
              <a
                className="nav-link justify-center"
                href="#socials"
                onClick={(ev) => {
                  ev.preventDefault();
                }}
              >
                <img src={googleIcon} alt="Google-Icon" className="google-icon" />
                <div className="google-text">Continue with Google</div>
              </a>
            </li>
          </ul> */}
        </PreviewCard>
      </Block>
      <AuthFooter />
    </>
  );
};
export default Register;
