import React, { useEffect, useState } from "react";
import Head from "../layout/head/Head";
import { Block, BlockHead, BlockHeadContent, BlockBetween, BlockTitle } from "../components/Component";
import { Label, Input, Row, Col, Button, Form, Spinner } from "reactstrap";
import axios from "axios";
import toast from "react-hot-toast";

const AdminDashboardSmtp = () => {
  const [loader, setLoader] = useState(false);
  const [apiLoader, setApiLoader] = useState(false);
  const [data, setData] = useState({
    host_name: "",
    port: "",
    port_type: false,
    username: "",
    password: "",
    email_from: "",
  });
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setData({
      ...data,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const getApiResponse = async () => {
    try {
      setApiLoader(true);
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/v1/admin/get-smtp-data`, {
        headers: {
          token: localStorage.getItem("adminToken"),
        },
      });
      setData({
        host_name: response.data.data.host_name,
        port: response.data.data.port,
        port_type: response.data.data.port_type,
        username: response.data.data.user_name,
        password: response.data.data.password,
        email_from: response.data.data.email_from,
      });
      setApiLoader(false);
    } catch (err) {
      setApiLoader(false);
      console.log(`error from api ${err}`);
    }
  };

  useEffect(() => {
    getApiResponse();
  }, []);

  const submitFn = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // if (emailRegex.test(formData.email)) {
    if (!data.email_from || !data.host_name || !data.password || !data.port || !data.username) {
      toast.error("all field are required");
      return;
    }
    if (emailRegex.test(data.email_from)) {
      try {
        setLoader(true);
        const sendResponse = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/v1/admin/update-smtp-settings`,
          {
            host_name: data.host_name,
            port: data.port,
            port_type: data.port_type,
            username: data.username,
            password: data.password,
            email_from: data.email_from,
          },
          {
            headers: {
              token: localStorage.getItem("adminToken"),
            },
          }
        );
        setLoader(false);
        if (sendResponse.data.status) {
          toast.success(sendResponse.data.message);
        } else {
          toast.error(sendResponse.data.message);
        }
      } catch (err) {
        setLoader(false);
      }
    } else {
      toast.error("invalid email");
    }
  };
  return (
    <React.Fragment>
      <Head title="SMTP-Setting" />
      {apiLoader ? (
        <div className="d-flex align-items-center justify-content-center" style={{ minHeight: "90vh" }}>
          <Spinner className="animate-spin text-center text-blue-800" />
        </div>
      ) : (
        <section className="container-fluid">
          <BlockHead size="sm" className="ms-3">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle page> Mail-Config</BlockTitle>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <Block className=" mx-3">
            {/* <Form onSubmit={submitFn}> */}
            <Row>
              <Col>
                <div className="form-group">
                  <Label htmlFor="host_name" className="form-label my-2">
                    Host Name
                  </Label>
                  <div className="form-control-wrap">
                    <input
                      className="form-control"
                      type="text"
                      id="host_name"
                      name="host_name"
                      placeholder="Host"
                      value={data.host_name}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="form-group">
                  <Label htmlFor="port" className="form-label my-2">
                    PORT
                  </Label>
                  <div className="form-control-wrap">
                    <input
                      className="form-control"
                      type="text"
                      id="port"
                      name="port"
                      placeholder="PORT"
                      value={data.port}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="form-group">
                  <Label htmlFor="port_type" className="form-label my-2">
                    PORT Type{" "}
                    <input
                      type="checkbox"
                      className="ms-2"
                      id="port_type"
                      name="port_type"
                      checked={data.port_type}
                      onChange={handleChange}
                    />
                  </Label>
                  {/* <div className="form-control-wrap"> */}

                  {/* </div> */}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="form-group">
                  <Label htmlFor="Username" className="form-label my-2">
                    Username
                  </Label>
                  <div className="form-control-wrap">
                    <input
                      className="form-control"
                      type="text"
                      id="Username"
                      name="username"
                      placeholder="Username"
                      value={data.username}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="form-group">
                  <Label htmlFor="email_from" className="form-label my-2">
                    Email id
                  </Label>
                  <div className="form-control-wrap">
                    <input
                      className="form-control"
                      type="email"
                      id="email_from"
                      name="email_from"
                      placeholder="Email"
                      value={data.email_from}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="form-group">
                  <Label htmlFor="password" className="form-label my-2">
                    Password
                  </Label>
                  <div className="form-control-wrap">
                    <input
                      className="form-control"
                      type="text"
                      id="password"
                      name="password"
                      placeholder="Password"
                      value={data.password}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <div>
              <Button className="mt-3" onClick={submitFn} disabled={loader} style={{ height: "35px" }}>
                {loader ? <Spinner /> : "Send"}
              </Button>
            </div>
            {/* </Form>{" "} */}
          </Block>
        </section>
      )}
    </React.Fragment>
  );
};

export default AdminDashboardSmtp;
