import React from "react";
import LogoLight2x from "../../images/logo2x.png";
import LogoDark2x from "../../images/logo-dark2x.png";
import { Link } from "react-router-dom";
import { name } from "../../utils/common";

const Logo = ({ to }) => {
  return (
    // <div>{name}</div>
    <Link to={to ? to : `${process.env.REACT_APP_PUBLIC_SUBDOMAIN_URL}/`} className="logo-link fs-1 fw-bold">
      {name}
      {/* <img className="logo-light logo-img" src={LogoLight2x} alt="logo" />
      <img className="logo-dark logo-img" src={LogoDark2x} alt="logo" /> */}
    </Link>
  );
};

export default Logo;
