import React from "react";
import grp1 from "../../assets/LandingPage/group1.png";

const HomeComp1 = () => {
  const navigate = () => {
    window.location.href = `${process.env.REACT_APP_PUBLIC_SUBDOMAIN_URL}/login`;
  };
  return (
    <div>
      <div className="Home_section_one">
        <div className="container d-flex justify-content-between pt-5">
          <div className="reco_logo">Reco</div>
          <div>
            <button className="get_started_btn" onClick={navigate}>
              Get Started
            </button>
          </div>
        </div>
        <div className="row m-0  d-flex justify-content-between home_section_one_group">
          <div className="col-12 col-lg-6 p-0 responsive_img">
            <img src={grp1} alt="group1" />
          </div>
          <div className="col-12 col-lg-6 home_section_content_area d-flex align-items-center justify-content-center">
            <div className="component-responsive">
              <div className="home_section_one_title ">Posting content had never been so easy!</div>
              <div className="home_section_one_description pt-2">
                Posting Reels,Shorts, and Tiktok videos with one click of a button!
              </div>
              <div className="lg-pt-3 mt-lg-5 md-text-center btn_responsive">
                <button className="get_started_btn" onClick={navigate}>
                  Get Started
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row ms-0 me-0 d-flex justify-content-center home_section_block ">
          <div className=" col-4 col-4 view_box  d-flex  justify-content-center align-items-center">
            <span className="home_block_one_digits">1M+</span> <span className="home_block_one_words">Users</span>
          </div>
          <div className="col-4 col-4 view_box d-flex justify-content-center align-items-center">
            <span className="home_block_one_digits">100M+ </span>
            <span className="home_block_one_words">Posts</span>
          </div>
          <div className="col-4 col-4 view_box   d-flex justify-content-center align-items-center">
            <span className="home_block_one_digits">3+ </span>
            <span className="home_block_one_words">Platforms</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeComp1;
