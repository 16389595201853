import React from "react";
import { Col, Container, Row } from "reactstrap";
import group3 from "../../assets/LandingPage/group3.png";

const HomeComp3 = () => {
  const navigate = () => {
    window.location.href = `${process.env.REACT_APP_PUBLIC_SUBDOMAIN_URL}/login`;
  };
  return (
    <div>
      <Container className="set_padding_top_seprate">
        <div className="d-flex flex-column-reverse flex-lg-row flex-md-column-reverse flex-sm-column-reverse m-0 row">
          <div className="col-lg-6 col-sm-12">
            <img src={group3} alt="group-image" />
          </div>
          <div className="home_block_description col-lg-6 col-sm-12 ps-lg-5 d-flex align-items-center">
            <div className="mb-5 component-responsive">
              <h2 className="home_comp_green">Set your Flow</h2>
              <div className="home_comp_para">
                Have multiple flows? Automate them all! All you need to do it add, and connect he accounts. From there
                its as easy as one click posting.
              </div>
              <div>
                <button className="get_started_btn_black" onClick={navigate}>
                  Get Started
                </button>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default HomeComp3;
