import React from "react";
import { Container } from "reactstrap";
import lines from "../../assets/LandingPage/lines.png";
import stars from "../../assets/LandingPage/stars.png";
import checkIcon from "../../assets/LandingPage/check.png";
const HomeComp5 = () => {
  return (
    <div className="set_padding_top">
      <Container>
        <div className="row m-0 pricing_main">
          <div className="col-lg-6 home_comp_pricing_left d-flex justify-center align-items-center">
            <div className="pricing_comp_left_content">
              <h1>Get started now with Reco</h1>
              <h6>Posting Made Easy!</h6>
            </div>
          </div>
          <div className="col-lg-6 d-flex flex-column  home_comp_pricing_right">
            <div className="pricing_block_lines">
              <img src={lines} />
            </div>
            <div className="d-flex justify-center">
              <div className="pricing_block">
                <div className="d-flex flex-column justify-center align-center pt-5 ">
                  <div>
                    <img src={stars} alt="stars" />
                  </div>
                  <div className="pt-3 subs_title_comp5">SUBSCRIPTION</div>
                  <div className="subs_Desc_comp5">THE BEST DEAL</div>
                  <div className="pricing_detail">
                    {" "}
                    $ <span className="home_comp5_price">9.99</span> <span>/mo</span>{" "}
                  </div>
                </div>
                <div className="">
                  <div className="app_benefit_block justify-content-sm-center">
                    <img src={checkIcon} alt="check" /> <span className="ps-2">Unlimited Flows</span>
                  </div>
                  <div className="app_benefit_block">
                    <img src={checkIcon} alt="check" /> <span className="ps-2">Connect any Account</span>
                  </div>
                  <div className="app_benefit_block">
                    <img src={checkIcon} alt="check" /> <span className="ps-2">One Click Posting</span>
                  </div>
                  <div className="app_benefit_block">
                    <img src={checkIcon} alt="check" /> <span className="ps-2">Never Miss a Post Again</span>
                  </div>
                </div>
                <div className=" d-flex justify-center">
                  <button
                    className=" w-75 get_started_btn_black_sec"
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                  >
                    Join Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default HomeComp5;
