import React, { useEffect, useState } from "react";
import axios from "axios";
import UserAvatar from "../../../../components/user/UserAvatar";
import { DropdownToggle, DropdownMenu, Dropdown } from "reactstrap";
import { Icon } from "../../../../components/Component";
import { LinkList } from "../../../../components/links/Links";

const User = () => {
  const [subDomain, setSubDomain] = useState(window.location.hostname.split(".")[0]);
  const [open, setOpen] = useState(false);
  const [userData, setUserData] = useState({});
  const toggle = () => setOpen((prevState) => !prevState);

  useEffect(() => {
    subDomain == "app" ? userDataInfo() : adminDataInfo();
  }, []);

  const userDataInfo = async () => {
    const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/v1/users/get-user-info`, {
      headers: {
        token: localStorage.getItem("accessToken"),
      },
    });
    if (response.data.status) {
      setUserData(response.data.data);
    }
  };

  const adminDataInfo = async () => {
    const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/v1/admin/get-admin-info`, {
      headers: {
        token: localStorage.getItem("adminToken"),
      },
    });
    if (response.data.status) {
      setUserData(response.data.data);
    }
  };

  return (
    <Dropdown isOpen={open} className="user-dropdown" toggle={toggle}>
      <DropdownToggle
        tag="a"
        href="#toggle"
        className="dropdown-toggle"
        onClick={(ev) => {
          ev.preventDefault();
        }}
      >
        <div className="user-toggle">
          <UserAvatar icon="user-alt" className="sm" />
        </div>
      </DropdownToggle>
      <DropdownMenu end className="dropdown-menu-md drop-menu">
        <div className="dropdown-inner user-card-wrap bg-lighter">
          <div className="user-card sm">
            {/* <div className="user-avatar">
              <span>AB</span>
            </div> */}
            <div className="user-avatar">
              <UserAvatar icon="user-alt" className="sm" />
            </div>
            <div className="user-info">
              <span className="lead-text">{userData.name}</span>
              <span className="sub-text">{userData.email}</span>
            </div>
          </div>
        </div>
        <div className="dropdown-inner">
          <LinkList>
            <a
              href={
                subDomain == "app"
                  ? `${process.env.REACT_APP_PUBLIC_SUBDOMAIN_URL}/`
                  : `${process.env.REACT_APP_PUBLIC_ADMIN_SUBDOMAIN_URL}/`
              }
              onClick={() =>
                subDomain == "app" ? localStorage.removeItem("accessToken") : localStorage.removeItem("adminToken")
              }
            >
              <Icon name="signout"></Icon>
              <span>Sign Out</span>
            </a>
          </LinkList>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default User;
