import React, { useEffect, useState } from "react";
import Head from "../layout/head/Head";
import { json, useNavigate } from "react-router-dom";
import axios from "axios";
import { Spinner } from "reactstrap";
const TermsCondition = () => {
  const [apiData, setApiData] = useState("");
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    const getResponse = async () => {
      setLoader(true);

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/v1/admin/get-admin-settings`,
          {
            type: 1,
          },
          {
            headers: {
              token: localStorage.getItem("adminToken"),
            },
          }
        );
        var plainString = response.data.data.html_data;
        setLoader(false);

        // const sanitizedHtml = DOMPurify.sanitize(response.data.data.html_data);
        setApiData(plainString);
      } catch (err) {
        // console.log(`error ${err}`);
        setLoader(false);
      }
    };
    getResponse();
  }, []);
  const navigate = useNavigate();
  return (
    <>
      {loader ? (
        <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "100vh" }}>
          <Spinner />
        </div>
      ) : (
        <section className="container">
          <Head title="Terms and Conditions" />
          <div className="row pb-3 pt-3">
            <h1 className="fw-bold" style={{ textAlign: "center", cursor: "pointer" }} onClick={() => navigate("/")}>
              Reco
            </h1>
          </div>

          <div>
            <div dangerouslySetInnerHTML={{ __html: apiData }} />
          </div>
        </section>
      )}
    </>
  );
};

export default TermsCondition;
