import React, { useState } from "react";
import axios from "axios";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Button,
  Icon,
  PreviewCard,
} from "../../components/Component";
import { Form, Spinner } from "reactstrap";
import { useForm } from "react-hook-form";
import { adminName } from "../../utils/common";
import toast from "react-hot-toast";

function AdminLogin() {
  const [loading, setLoading] = useState(false);
  const [passState, setPassState] = useState(false);

  const onFormSubmit = async (formData) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(formData.email)) {
      document.getElementById("default-01").disabled = true;
      document.getElementById("password").disabled = true;
      setLoading(true);
      await axios
        .post(
          `${process.env.REACT_APP_SERVER_URL}/v1/admin/signin`,
          {
            email: formData.email.toLowerCase(),
            password: formData.passcode,
          },
          {
            headers: { "Access-Control-Allow-Origin": "*" },
          }
        )
        .then((response) => {
          if (response.data.status) {
            localStorage.setItem("adminToken", response.data.data.user_token);
            window.location.href = `${process.env.REACT_APP_PUBLIC_ADMIN_SUBDOMAIN_URL}/`;
            // console.log(`${process.env.REACT_APP_PUBLIC_ADMIN_SUBDOMAIN_URL}/`);
          } else {
            document.getElementById("default-01").disabled = false;
            document.getElementById("password").disabled = false;
            setLoading(false);
            if (response.data.message.email_phone === "Email is not valid") {
              toast.error(response.data.message.email_phone);
            } else {
              toast.error(response.data.message);
            }
          }
        })
        .catch((response) => {
          document.getElementById("default-01").disabled = false;
          document.getElementById("password").disabled = false;
          setLoading(false);
          // console.log("response: ", response);
          toast.error(`Server Error. Please try again later. ${response.message}`);
        });
    } else {
      setLoading(false);
      toast.error("Invalid Email");
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const navigate = () => {
    window.location.href = `${process.env.REACT_APP_PUBLIC_URL}/`;
  };
  return (
    <>
      <Head title="Admin Login" />
      <Block className="nk-block-middle nk-auth-body  wide-xs">
        <div className="brand-logo mb-4 text-center fw-bold" onClick={navigate}>
          {adminName}
          {/* <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
            <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
            <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" />
          </Link> */}
        </div>

        <PreviewCard className="" bodyClass="card-inner-lg">
          <BlockHead>
            <BlockContent>
              <BlockTitle tag="h4">Admin Sign-In</BlockTitle>
              <BlockDes>
                <p>Access {adminName} using your email and password.</p>
              </BlockDes>
            </BlockContent>
          </BlockHead>
          <Form className="is-alter" onSubmit={handleSubmit(onFormSubmit)}>
            <div className="form-group">
              <div className="form-label-group">
                <label className="form-label" htmlFor="default-01">
                  Email
                </label>
              </div>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="default-01"
                  {...register("email", {
                    required: true,
                    pattern: {
                      value: /^[a-zA-Z0-9_.±]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/,
                    },
                  })}
                  placeholder="Enter your email address"
                  className="form-control-lg form-control"
                />
                {errors.email && <p className="invalid">Please enter a valid email address.</p>}
              </div>
            </div>
            <div className="form-group">
              <div className="form-label-group">
                <label className="form-label" htmlFor="password">
                  Password
                </label>
              </div>
              <div className="form-control-wrap">
                <a
                  href="#password"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setPassState(!passState);
                  }}
                  className={`form-icon lg form-icon-right passcode-switch ${passState ? "is-hidden" : "is-shown"}`}
                >
                  <Icon name="eye" className="passcode-icon icon-show"></Icon>

                  <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                </a>
                <input
                  type={passState ? "text" : "password"}
                  id="password"
                  {...register("passcode", { required: "This field is required" })}
                  placeholder="Enter your password"
                  className={`form-control-lg form-control ${passState ? "is-hidden" : "is-shown"}`}
                />
                {errors.passcode && <span className="invalid">{errors.passcode.message}</span>}
              </div>
            </div>
            <div className="form-group justify-center">
              {loading ? (
                <Spinner size="md" color="primary" />
              ) : (
                <Button color="primary" size="lg" className="btn-block" type="submit">
                  Sign in
                </Button>
              )}
            </div>
          </Form>
        </PreviewCard>
      </Block>
      {/* <AuthFooter /> */}
    </>
  );
}

export default AdminLogin;
