import React, { useEffect, useState } from "react";
import axios from "axios";
import Head from "../layout/head/Head";
import { useNavigate } from "react-router-dom";
import { Spinner } from "reactstrap";
const PrivacyPolicy = () => {
  const [apiData, setApiData] = useState("");
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const getResponse = async () => {
      setLoader(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/v1/admin/get-admin-settings`,
          {
            type: 2,
          },
          {
            headers: {
              token: localStorage.getItem("adminToken"),
            },
          }
        );
        var plainString = response.data.data.html_data;
        setLoader(false);

        // const sanitizedHtml = DOMPurify.sanitize(response.data.data.html_data);
        setApiData(plainString);
      } catch (err) {
        // console.log(`error ${err}`);
        setLoader(false);
      }
    };
    getResponse();
  }, []);
  return (
    <>
      {loader ? (
        <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "100vh" }}>
          <Spinner />
        </div>
      ) : (
        <section className="container">
          <Head title="Privacy Policy" />
          <h1 className="fw-bold mt-3" style={{ textAlign: "center", cursor: "pointer" }} onClick={() => navigate("/")}>
            Reco
          </h1>

          <div>
            <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
            <meta name="Generator" content="Microsoft Word 15 (filtered)" />
            <style
              dangerouslySetInnerHTML={{
                __html:
                  '\n<!--\n /* Font Definitions */\n @font-face\n\t{font-family:"Cambria Math";\n\tpanose-1:2 4 5 3 5 4 6 3 2 4;}\n@font-face\n\t{font-family:Times;\n\tpanose-1:2 2 6 3 5 4 5 2 3 4;}\n /* Style Definitions */\n p.fs-18px, li.fs-18px, div.fs-18px\n\t{margin:0in;\n\tfont-size:12.0pt;\n\tfont-family:"Times New Roman",serif;}\n.MsoChpDefault\n\t{font-size:10.0pt;}\n@page WordSection1\n\t{size:8.5in 11.0in;\n\tmargin:1.0in 1.0in 1.0in 1.0in;}\ndiv.WordSection1\n\t{page:WordSection1;}\n-->\n',
              }}
            />

            <div dangerouslySetInnerHTML={{ __html: apiData }} />
          </div>
        </section>
      )}
    </>
  );
};

export default PrivacyPolicy;
