import React, { useEffect, useState } from "react";
import axios from "axios";
import { RSelect, Button } from "../components/Component";

const ConnectionDes = ({
  setTypes,
  setDestinationType,
  destinationType,
  setDestinationVideoType,
  destinationVideoType,
  workflowAdd,
  sourceType,
  setLoader,
}) => {
  const [optionsTag, setOptionsTag] = useState([]);
  const options = [
    { id: 1, value: 1, label: "Instagram", disabled: true },
    { id: 2, value: 2, label: "TikTok", disabled: true },
    { id: 3, value: 3, label: "Youtube", disabled: true },
  ];

  useEffect(() => {
    getAllConnections();
  }, []);

  useEffect(() => {
    if (optionsTag.length > 0) {
      optionsTag.map((data) => {
        if (data.value == sourceType.value) {
          data.disabled = true;
        }
      });
    }
  }, [optionsTag]);

  const getAllConnections = async () => {
    const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/v1/users/get-connections`, {
      headers: {
        token: localStorage.getItem("accessToken"),
      },
    });
    // console.log("response: ", response);
    response.data.data.map((el) => {
      options.map((data) => {
        if (data.value == el.platform_id) {
          data.disabled = false;
        }
      });
    });
    setOptionsTag([...options]);
  };

  const forwardFn = (e) => {
    e.preventDefault();
    // setTypes(2)
    setLoader(true);
    workflowAdd();
  };
  const backFn = (e) => {
    e.preventDefault();
    setDestinationType("");
    setDestinationVideoType("");
    setTypes(1);
  };

  // const vOptions = [
  //   // { id: 1, value: "Reels", label: "Reels" },
  //   { id: 2, value: "Shorts", label: "Shorts" },
  //   // { id: 3, value: "TikTok", label: "TikTok" },
  // ];

  return (
    <div className="destination-type-div">
      <p className="set_paraTag_source">Choose a Destination Connection</p>
      <RSelect
        options={optionsTag}
        isOptionDisabled={(option) => option.disabled}
        onChange={(e) => setDestinationType(e)}
        value={destinationType}
      />
      {/* {destinationType != "" ? (
        <>
          <p className="set_paraTag_source">Choose a Destination Video type</p>
          <RSelect options={vOptions} onChange={(e) => setDestinationVideoType(e)} value={destinationVideoType} />
        </>
      ) : (
        ""
      )} */}
      {/* <div className="form-label-group">
        <p className="set_paraTag_source">Create a new playlist for YouTube(Optional)</p>
      </div>
      <div className="form-control-wrap">
        <input
          type="text"
          id="default-01"
          placeholder="Enter the name for new playlist (optional)"
          className="form-control-lg form-control"
        />
      </div>
      <Button onClick={forwardFn} size="lg" className="mt-4 fs-19px" color="primary">
        Create a Playlist
      </Button> */}
      {destinationType != "" ? (
        <div className="text-center">
          <Button onClick={backFn} size="lg" className="mt-4   me-2 btn_back justify-content-center" color="">
            BACK
          </Button>
          <Button onClick={forwardFn} size="lg" className="mt-4" color="primary">
            DONE
          </Button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default ConnectionDes;
