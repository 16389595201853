import React, { useState, useEffect, Fragment } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import { Spinner } from "reactstrap";

function TikTokCallback() {
  const [tiktokCode, setTiktokCode] = useState("");
  const [loader, setLoader] = useState(false);
  const [runTime, setRunTime] = useState(0);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (runTime == 0) {
      if (searchParams.get("error")) {
        toast.error("Access Denied");
        localStorage.removeItem("connectionName");
        setTimeout(() => (window.location.href = `${process.env.REACT_APP_PUBLIC_SUBDOMAIN_URL}/connections`), 1000);
      } else {
        setTiktokCode(searchParams.get("code"));
      }
      setRunTime(1);
    }
  }, []);

  useEffect(() => {
    if (tiktokCode != "") {
      setLoader(true);
      tiktokAuthGenerate();
    }
  }, [tiktokCode]);

  const tiktokAuthGenerate = () => {
    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/v1/auth/tiktok-get-token`,
        {
          code: tiktokCode,
          connectionName: localStorage.getItem("connectionName"),
          user_token: localStorage.getItem("accessToken"),
        },
        {
          headers: {
            token: localStorage.getItem("accessToken"),
          },
        }
      )
      .then((res) => {
        // console.log(res.data);
        if (res.data.status) {
          toast.success(res.data.message);
          localStorage.removeItem("connectionName");
          setTimeout(() => (window.location.href = `${process.env.REACT_APP_PUBLIC_SUBDOMAIN_URL}/connections`), 1000);
        } else {
          toast.error(res.data.message);
          localStorage.removeItem("connectionName");
        }
      })
      .catch((err) => {
        toast.error(err.message);
        localStorage.removeItem("connectionName");
      });
  };

  return (
    <Fragment>
      {loader ? (
        <div className="d-flex justify-content-center mt-4">
          <Spinner color="primary" />
        </div>
      ) : (
        ""
      )}
    </Fragment>
  );
}

export default TikTokCallback;
