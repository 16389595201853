import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card, Col, Row, Spinner } from "reactstrap";
import toast from "react-hot-toast";
import AdminDashboardGraph from "./AdminDashboardGraph";
import { addDays } from "date-fns";

const AdminDashboardStats = () => {
  const [data, setData] = useState([]);
  const [dd, setdd] = useState("Last Month");
  const [type, setType] = useState("last_month");
  const [rangeStart, setRangeStart] = useState(new Date());
  const [rangeEnd, setRangeEnd] = useState(new Date());
  const [label, setLabel] = useState([]);
  const [instagramData, setInstagramData] = useState([]);
  const [youtubeData, setYoutubeData] = useState([]);
  const [tiktokData, setTiktoData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loaderStats, setLoaderStats] = useState(false);

  const performanceOverview = {
    labels: label,
    dataUnit: "Users",
    lineTension: 0.4,
    legend: true,
    categoryPercentage: 0.9,
    barPercentage: 0.6,
    datasets: [
      {
        label: "Instagram",
        backgroundColor: "transparent",
        // borderWidth: 2,
        borderColor: "#733AEA",
        // pointBorderColor: "transparent",
        // pointBackgroundColor: "transparent",
        // pointHoverBackgroundColor: "#fff",
        // pointHoverBorderColor: "#733AEA",
        pointBorderWidth: 2,
        // pointHoverRadius: 4,
        // pointHoverBorderWidth: 2,
        // pointRadius: 4,
        // pointHitRadius: 4,
        // data: [
        //   0, 20, 44, 60, 60, 60, 60, 60, 60, 95, 138, 126, 110, 90, 170, 250, 290, 280, 270, 260, 245, 225, 205, 230,
        //   264, 280, 300, 370, 440, 420, 460,
        // ],
        fill: false,
        data: instagramData,
      },
      {
        label: "Youtube",
        backgroundColor: "transparent",
        // borderWidth: 2,
        borderColor: "#FD9722",
        // pointBorderColor: "transparent",
        // pointBackgroundColor: "transparent",
        // pointHoverBackgroundColor: "#fff",
        // pointHoverBorderColor: "#FD9722",
        pointBorderWidth: 2,
        // pointHoverRadius: 4,
        // pointHoverBorderWidth: 2,
        // pointRadius: 4,
        // pointHitRadius: 4,
        // data: [
        //   100, 85, 80, 65, 75, 90, 115, 130, 150, 170, 185, 195, 240, 280, 320, 325, 330, 340, 350, 335, 325, 315, 305,
        //   295, 330, 370, 390, 420, 480, 550, 600,
        // ],
        fill: false,
        data: youtubeData,
      },
      {
        label: "TikTok",
        backgroundColor: "transparent",
        // borderWidth: 2,
        borderColor: "#078EFC",
        // pointBorderColor: "transparent",
        // pointBackgroundColor: "transparent",
        // pointHoverBackgroundColor: "#fff",
        // pointHoverBorderColor: "#FD9722",
        pointBorderWidth: 2,
        // pointHoverRadius: 4,
        // pointHoverBorderWidth: 2,
        // pointRadius: 4,
        // pointHitRadius: 4,
        // data: [
        //   100, 85, 80, 65, 75, 90, 115, 130, 150, 170, 185, 195, 240, 280, 320, 325, 330, 340, 350, 335, 325, 315, 305,
        //   295, 330, 370, 390, 420, 480, 550, 600,
        // ],
        fill: false,
        data: tiktokData,
      },
    ],
  };

  useEffect(() => {
    getDashboardStats();
  }, []);

  const getGraphData = async () => {
    setLoader(true);
    const newStart = addDays(rangeStart, 1);
    const newEnd = addDays(rangeEnd, 1);
    try {
      axios
        .post(
          `${process.env.REACT_APP_SERVER_URL}/v1/admin/get-graph-info`,
          {
            filter_type: type,
            start_date: newStart,
            end_date: newEnd,
          },
          { headers: { token: localStorage.getItem("adminToken") } }
        )
        .then((response) => {
          setLoader(false);
          setLabel(response.data.data.date);
          setInstagramData(response.data.data.totalInstagramPosts);
          setYoutubeData(response.data.data.totalYoutubePosts);
          setTiktoData(response.data.data.totalTikTokPosts);
        });
    } catch (err) {
      // console.log(`error is erjrjfjf ${err.message}`);
      toast.error(err.message);
      setLoader(false);
    }
  };
  function loading() {
    return <Spinner />;
  }

  useEffect(() => {
    if (type != "range" && rangeStart == "" && rangeEnd == "") {
      getGraphData();
    } else if (type == "range" && rangeStart != "" && rangeEnd != "" && rangeStart != rangeEnd) {
      getGraphData();
    }
  }, [type, rangeStart, rangeEnd]);

  const getDashboardStats = async () => {
    setLoaderStats(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/v1/admin/get-user-stats`, {
        headers: {
          token: localStorage.getItem("adminToken"),
        },
      });
      if (response.data.status) {
        // toast.success(response.data.message);
        setTimeout(() => {
          setData(response.data.data);
          // console.log(response.data.data);
          setLoaderStats(false);
        }, 1000);
      } else {
        toast.success(response.data.message);
        setTimeout(() => {
          setData(response.data.data);
          setLoaderStats(false);
        }, 1000);
      }
    } catch (err) {
      setLoaderStats(false);
      toast.error(err.message);
    }
  };
  const statusCard = [
    {
      title: "Active Users",
      theme: "primary",
      link: {
        text: "See All",
        to: "user-listing",
      },
      amount: `${data.users ? data.users : 0}`,
      amountText: "Users",
    },
    {
      title: "Active Workflows",
      theme: "info",
      link: {
        text: "See All",
        to: "user-listing",
      },
      amount: `${data.workflows ? data.workflows : 0}`,
      amountText: "Workflows",
    },
    {
      title: "Successfull Post",
      theme: "danger",
      link: {
        text: "See All",
        to: "question-analytics",
      },
      amount: `${data.posts ? data.posts : 0}`,
      amountText: "Post",
    },
    {
      title: "Active Instagram Connections",
      theme: "success",
      link: {
        text: "See All",
        to: "question-analytics",
      },
      amount: `${data.instagramConnections ? data.instagramConnections : 0}`,
      amountText: "Instagram",
    },
    {
      title: "Active Youtube Connections",
      theme: "warning",
      link: {
        text: "See All",
        to: "question-analytics",
      },
      amount: `${data.youtubeConnections ? data.youtubeConnections : 0}`,
      amountText: "Youtube",
    },
    {
      title: "Active TikTok connections",
      theme: "secondary",
      link: {
        text: "See All",
        to: "question-analytics",
      },
      amount: `${data.tiktokConnections ? data.tiktokConnections : 0}`,
      amountText: "TikTok",
    },
  ];
  return (
    <div>
      <Row className="g-gs">
        {Object.keys(statusCard).length > 0
          ? statusCard.map((item, index) => (
              <Col key={index} xxl="4" sm="6">
                <Card className={`card-full dashboard-card pt-1 bg-${item.theme}`} style={{ borderRadius: "10psx" }}>
                  <div className="card-inner">
                    <div className="d-flex align-items-center justify-content-between mb-1">
                      <div className="fs-6 text-white text-opacity-75 mb-0">{item.title}</div>
                      {/* {item.link && (
                      <Link to={`${process.env.PUBLIC_URL}/${item.link.to}`} className="link link-white">
                        {item.link.text}
                      </Link>
                    )} */}
                    </div>
                    <h5 className="fs-1 text-white">
                      {loaderStats ? <Spinner /> : item.amount} <small className="fs-3">{item.amountText}</small>
                    </h5>
                  </div>
                </Card>
              </Col>
            ))
          : ""}
      </Row>
      <Row>
        <Col xxl="12" className="mt-5">
          <AdminDashboardGraph
            title="Posts"
            chartData={performanceOverview}
            dd={dd}
            setType={setType}
            setdd={setdd}
            rangeStart={rangeStart}
            setRangeStart={setRangeStart}
            rangeEnd={rangeEnd}
            setRangeEnd={setRangeEnd}
            labelCheck={label}
            loader={loader}
          />
        </Col>
      </Row>
    </div>
  );
};

export default AdminDashboardStats;
