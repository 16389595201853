import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import exportFromJSON from "export-from-json";
import CopyToClipboard from "react-copy-to-clipboard";
import { Col, Modal, ModalBody, Row, Button, Spinner } from "reactstrap";
import { DataTablePagination } from "../Component";

const Export = ({ data }) => {
  const [modal, setModal] = useState(false);

  useEffect(() => {
    if (modal === true) {
      setTimeout(() => setModal(false), 2000);
    }
  }, [modal]);

  const fileName = "user-data";

  const exportCSV = () => {
    const exportType = exportFromJSON.types.csv;
    exportFromJSON({ data, fileName, exportType });
  };

  const exportExcel = () => {
    const exportType = exportFromJSON.types.xls;
    exportFromJSON({ data, fileName, exportType });
  };

  const copyToClipboard = () => {
    setModal(true);
  };

  return (
    <React.Fragment>
      <div className="dt-export-buttons d-flex align-center">
        <div className="dt-export-title d-none d-md-inline-block">Export</div>
        <div className="dt-buttons btn-group flex-wrap">
          <CopyToClipboard text={JSON.stringify(data)}>
            <Button className="buttons-copy buttons-html5" onClick={() => copyToClipboard()}>
              <span>Copy</span>
            </Button>
          </CopyToClipboard>{" "}
          <button className="btn btn-secondary buttons-csv buttons-html5" type="button" onClick={() => exportCSV()}>
            <span>CSV</span>
          </button>{" "}
          <button className="btn btn-secondary buttons-excel buttons-html5" type="button" onClick={() => exportExcel()}>
            <span>Excel</span>
          </button>{" "}
        </div>
      </div>
      <Modal isOpen={modal} className="modal-dialog-centered text-center" size="sm">
        <ModalBody className="text-center m-2">
          <h5>Copied to clipboard</h5>
        </ModalBody>
        <div className="p-3 bg-light">
          <div className="text-center">Copied {data.length} rows to clipboard</div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

const ExpandableRowComponent = ({ data }) => {
  return (
    <ul className="dtr-details p-2 border-bottom ms-1">
      <li className="d-block d-sm-none">
        <span className="dtr-title">Company</span> <span className="dtr-data">{data.company}</span>
      </li>
      <li className="d-block d-sm-none">
        <span className="dtr-title ">Gender</span> <span className="dtr-data">{data.gender}</span>
      </li>
      <li>
        <span className="dtr-title">Start Date</span> <span className="dtr-data">{data.startDate}</span>
      </li>
      <li>
        <span className="dtr-title">Salary</span> <span className="dtr-data">{data.salary}</span>
      </li>
    </ul>
  );
};

const CustomCheckbox = React.forwardRef(({ onClick, ...rest }, ref) => (
  <div className="custom-control custom-control-sm custom-checkbox notext">
    <input id={rest.name} type="checkbox" className="custom-control-input" ref={ref} onClick={onClick} {...rest} />
    <label className="custom-control-label" htmlFor={rest.name} />
  </div>
));

const ReactDataTable = ({
  data,
  columns,
  pagination,
  actions,
  className,
  selectableRows,
  expandableRows,
  rowsPerPageS,
  setRowsPerPage,
  setCurrentPage,
  currentPage,
  rowCount,
  type,
  searchText,
  setSearchText,
  loader,
}) => {
  // const [tableData, setTableData] = useState(data);

  const [mobileView, setMobileView] = useState();
  const totalPages = Math.ceil(rowCount / rowsPerPageS);
  console.log("totalPages", totalPages);
  // function to change the design view under 1200 px
  const viewChange = () => {
    if (window.innerWidth < 960 && expandableRows) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  };
  const itemsPerPageOptions = Array.from({ length: totalPages }, (_, i) => (i + 1) * 10);
  useEffect(() => {
    // console.log("data length", data.length);
    // setTableData(data);
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    return () => {
      window.removeEventListener("resize", viewChange);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={`dataTables_wrapper dt-bootstrap4 no-footer ${className ? className : ""}`}>
      <Row className={`justify-between g-2 ${actions ? "with-export" : ""}`}>
        <Col className="col-7 text-start" sm="4">
          <div id="DataTables_Table_0_filter" className="dataTables_filter">
            {type == 2 ? (
              <label style={{ width: "194px" }}>
                <input
                  type="search"
                  className="form-control form-control-sm"
                  placeholder="Search by name & email"
                  value={searchText}
                  onChange={(ev) => {
                    setSearchText(ev.target.value);
                    setCurrentPage(1);
                  }}
                />
              </label>
            ) : (
              ""
            )}
          </div>
          {/* setCurrentPage(1); */}
        </Col>
        {type == 1 ? (
          ""
        ) : (
          <Col className="col-5 text-end" sm="8">
            <div className="datatable-filter">
              <div className="d-flex justify-content-end g-2">
                {actions && <Export data={data} />}
                <div className="dataTables_length" id="DataTables_Table_0_length">
                  <label>
                    <span className="d-none d-sm-inline-block">Show</span>
                    <div className="form-control-select">
                      {" "}
                      <select
                        name="DataTables_Table_0_length"
                        className="custom-select custom-select-sm form-control form-control-sm"
                        onChange={(e) => {
                          setRowsPerPage(e.target.value);
                          setCurrentPage(1);
                        }}
                        value={rowsPerPageS}
                      >
                        <option value="10">10</option>
                        {rowCount > 10 && <option value="20">20</option>}
                        {rowCount > 20 && <option value="30">30</option>}
                        {rowCount > 30 && <option value="40">40</option>}
                        {rowCount > 70 && <option value="75">75</option>}
                        {rowCount > 90 && <option value="100">100</option>}
                      </select>{" "}
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </Col>
        )}
      </Row>
      <DataTable
        data={data}
        colHeaders={true}
        columns={columns}
        className={className}
        selectableRows={selectableRows}
        selectableRowsComponent={CustomCheckbox}
        expandableRowsComponent={ExpandableRowComponent}
        expandableRows={mobileView}
        progressPending={type == 2 && loader}
        progressComponent={<Spinner color="primary" />}
        noDataComponent={<div className="p-2">There are no records found</div>}
        sortIcon={
          <div>
            <span>&darr;</span>
            <span>&uarr;</span>
          </div>
        }
        pagination={pagination}
        paginationComponent={({ onChangeRowsPerPage }) => (
          <DataTablePagination
            customItemPerPage={rowsPerPageS}
            itemPerPage={rowsPerPageS}
            totalItems={rowCount}
            paginate={setCurrentPage}
            currentPage={currentPage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        )}
      ></DataTable>
    </div>
  );
};

export default ReactDataTable;
