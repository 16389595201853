import React, { useState } from "react";
import axios from "axios";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import { Block, BlockContent, BlockDes, BlockHead, BlockTitle, Button, PreviewCard } from "../../components/Component";
import { Link } from "react-router-dom";
import { name } from "../../utils/common";
import { Form, Spinner } from "reactstrap";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const navigate = () => {
    window.location.href = `${process.env.REACT_APP_PUBLIC_URL}/`;
  };

  const onFormSubmit = async (formData) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(formData.email)) {
      document.getElementById("default-01").disabled = true;
      setLoading(true);
      await axios
        .post(
          `${process.env.REACT_APP_SERVER_URL}/v1/users/forgotPassword`,
          {
            email: formData.email.toLowerCase(),
          },
          {
            headers: { "Access-Control-Allow-Origin": "*" },
          }
        )
        .then((response) => {
          document.getElementById("default-01").disabled = false;
          setLoading(false);
          if (response.data.status) {
            toast.success(response.data.message);
          } else {
            toast.error(response.data.message);
          }
        })
        .catch((response) => {
          document.getElementById("default-01").disabled = false;
          setLoading(false);
          toast.error(`Server Error. Please try again later. ${response.message}`);
        });
    } else {
      // setLoading(false);
      toast.error("Invalid Email");
    }
  };

  return (
    <>
      <Head title="Forgot-Password" />
      <Block className="nk-block-middle nk-auth-body  wide-xs">
        <div className="brand-logo mb-4 text-center fw-bold" onClick={navigate}>
          {name}
          {/* <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
            <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
            <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" />
          </Link> */}
        </div>
        <PreviewCard className="" bodyClass="card-inner-lg">
          <BlockHead>
            <BlockContent>
              <BlockTitle tag="h5">Reset password</BlockTitle>
              <BlockDes>
                <p>If you forgot your password, well, then we’ll email you instructions to reset your password.</p>
              </BlockDes>
            </BlockContent>
          </BlockHead>
          <Form className="is-alter" onSubmit={handleSubmit(onFormSubmit)}>
            <div className="form-group">
              <div className="form-label-group">
                <label className="form-label" htmlFor="default-01">
                  Email
                </label>
              </div>
              <div className="form-control-wrap">
                <input
                  type="text"
                  className="form-control form-control-lg"
                  id="default-01"
                  placeholder="Enter your email address"
                  {...register("email", {
                    required: true,
                    pattern: {
                      value: /^[a-zA-Z0-9_.±]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/,
                    },
                  })}
                />
                {errors.email && <p className="invalid">Please enter a valid email address.</p>}
              </div>
            </div>
            <div className="form-group justify-center">
              {loading ? (
                <Spinner size="md" color="primary" />
              ) : (
                <Button color="primary" size="lg" className="btn-block" type="submit">
                  Send Reset Link
                </Button>
              )}
            </div>
          </Form>
          <div className="form-note-s2 text-center pt-4">
            <Link to={`${process.env.REACT_APP_PUBLIC_SUBDOMAIN_URL}/login`}>
              <strong>Return to login</strong>
            </Link>
          </div>
        </PreviewCard>
      </Block>
      <AuthFooter />
    </>
  );
};
export default ForgotPassword;
