import React from "react";
import { Outlet } from "react-router-dom";
import Head from "./head/Head";
import toast, { Toaster } from "react-hot-toast";

const Layout = ({ title, ...props }) => {
  return (
    <>
      <Toaster
        toastOptions={{
          className: "",
          style: {
            border: "1px solid #713200",
            padding: "16px",
            color: "#713200",
          },
        }}
      />
      <Head title={!title && "Loading"} />
      <div className="nk-app-root">
        <div className="nk-wrap nk-wrap-nosidebar">
          <Outlet />
        </div>
      </div>
    </>
  );
};
export default Layout;
