import React, { useState } from "react";
import { Form } from "reactstrap";
import { Button } from "../components/Component";
const NameCreation = ({ setTypes, setWorkflowName, workflowName }) => {
  const submitFn = (e) => {
    e.preventDefault();
    // if(nameVal ==""){
    //   return
    // }else{

    setTypes(1);
    // }
  };
  return (
    <div>
      <Form>
        <div className="form-group">
          <div className="form-label-group">
            <p className="set_paraTag_source">Name this Workflow*</p>
          </div>
          <div className="form-control-wrap">
            <input
              type="text"
              id="default-01"
              className="form-control-lg form-control"
              value={workflowName}
              onChange={(e) => {
                var val = e.target.value.trim();
                if (val != "") {
                  setWorkflowName(e.target.value);
                } else {
                  setWorkflowName("");
                }
              }}
            />
          </div>
          {workflowName != "" ? (
            <div className="d-flex justify-content-center">
              <Button onClick={submitFn} size="lg" className="mt-4" type="submit" color="primary">
                NEXT
              </Button>
            </div>
          ) : (
            ""
          )}
        </div>
      </Form>
    </div>
  );
};

export default NameCreation;
