import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Button,
  Icon,
  PreviewCard,
} from "../components/Component";
import Head from "../layout/head/Head";
import { name } from "../utils/common";
import { Form, Spinner } from "reactstrap";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import AuthFooter from "./auth/AuthFooter";
import { useNavigate } from "react-router-dom";

function ResetPassword() {
  const [loading, setLoading] = useState(false);
  const [passState, setPassState] = useState(false);

  const navigateTo = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    checkToken();
  }, []);

  const navigate = () => {
    window.location.href = `${process.env.REACT_APP_PUBLIC_URL}/`;
  };

  const checkToken = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/v1/users/forgot-pass-token-check`, {
        fToken: localStorage.getItem("forgotPassToken"),
      });
      if (response.data.status) {
        // console.log(response.data.message);
      } else {
        localStorage.removeItem("forgotPassToken");
        window.location.href = `${process.env.REACT_APP_PUBLIC_SUBDOMAIN_URL}/errors/404`;
      }
    } catch (err) {
      localStorage.removeItem("forgotPassToken");
      window.location.href = `${process.env.REACT_APP_PUBLIC_SUBDOMAIN_URL}/errors/404`;
    }
  };

  const onFormSubmit = async (formData) => {
    document.getElementById("password").disabled = true;
    setLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/v1/users/change-password`,
        {
          password: formData.passcode,
          forgotPassToken: localStorage.getItem("forgotPassToken"),
        },
        {
          headers: { "Access-Control-Allow-Origin": "*" },
        }
      )
      .then((response) => {
        if (response.data.status) {
          toast.success(response.data.message);
          localStorage.removeItem("forgotPassToken");
          setTimeout(() => (window.location.href = `${process.env.REACT_APP_PUBLIC_SUBDOMAIN_URL}/login`), 2000);
        } else {
          document.getElementById("password").disabled = false;
          setLoading(false);
          toast.error(response.data.message);
        }
      })
      .catch((response) => {
        document.getElementById("password").disabled = false;
        setLoading(false);
        toast.error(`Server Error. Please try again later. ${response.message}`);
        setTimeout(() => navigateTo("/errors/404"), 1000);
      });
  };

  return (
    <>
      <Head title="Reset Password" />
      <Block className="nk-block-middle nk-auth-body  wide-xs">
        <div className="brand-logo mb-4 text-center fw-bold" onClick={navigate}>
          {name}
          {/* <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
            <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
            <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" />
          </Link> */}
        </div>

        <PreviewCard className="" bodyClass="card-inner-lg">
          <BlockHead>
            <BlockContent>
              <BlockTitle tag="h4">Reset Password</BlockTitle>
              <BlockDes>
                <p>Enter your new password.</p>
              </BlockDes>
            </BlockContent>
          </BlockHead>
          <Form className="is-alter" onSubmit={handleSubmit(onFormSubmit)}>
            <div className="form-group">
              <div className="form-label-group">
                <label className="form-label" htmlFor="password">
                  New Password
                </label>
              </div>
              <div className="form-control-wrap">
                <a
                  href="#password"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setPassState(!passState);
                  }}
                  className={`form-icon lg form-icon-right passcode-switch ${passState ? "is-hidden" : "is-shown"}`}
                >
                  <Icon name="eye" className="passcode-icon icon-show"></Icon>

                  <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                </a>
                <input
                  type={passState ? "text" : "password"}
                  id="password"
                  {...register("passcode", {
                    required: true,
                    pattern: { value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{5,}$/ },
                  })}
                  placeholder="Enter your password"
                  className={`form-control-lg form-control ${passState ? "is-hidden" : "is-shown"}`}
                />
                {errors.passcode && (
                  <span className="invalid">
                    Password should contain at least one uppercase letter, one lowercase letter, one number, one special
                    character and must be a minimum length of 5 characters
                  </span>
                )}
              </div>
            </div>
            <div className="form-group justify-center">
              {loading ? (
                <Spinner size="md" color="primary" />
              ) : (
                <Button color="primary" size="lg" className="btn-block" type="submit">
                  Submit
                </Button>
              )}
            </div>
          </Form>
        </PreviewCard>
      </Block>
      <AuthFooter />
    </>
  );
}

export default ResetPassword;
