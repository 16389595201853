import React from "react";
import Head from "../layout/head/Head";
import { Block, BlockHead, BlockHeadContent, BlockBetween, BlockTitle, Row } from "../components/Component";
import AdminDashboardStats from "./AdminDashboardStats";

function AdminDashboard() {
  return (
    <React.Fragment>
      <Head title="Admin Dashboard" />
      <section className="container-fluid">
        <BlockHead size="sm" className="mx-3">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>Dashboard</BlockTitle>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block className="responsive-workflow mx-3">
          <Row className="fs-1 fw-bold d-flex justify-content-center">
            <AdminDashboardStats />
          </Row>
        </Block>
      </section>
    </React.Fragment>
  );
}

export default AdminDashboard;
