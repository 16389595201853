import React, { Fragment } from "react";

const Footer = () => {
  return (
    <div className="bg-black">
      <div className="d-flex justify-content-center mb-3 pt-5">
        <a
          className="me-3"
          style={{ color: "#00c056" }}
          href={`${process.env.REACT_APP_PUBLIC_URL}/terms-conditions`}
          target="_blank"
        >
          Terms & Conditions
        </a>
        <a
          className="ms-3"
          style={{ color: "#00c056" }}
          href={`${process.env.REACT_APP_PUBLIC_URL}/privacy-policy`}
          target="_blank"
        >
          Privacy Policy
        </a>
      </div>
      <div className="text-white d-flex flex-column flex-md-row justify-content-center align-items-center  pb-5">
        © 2024 Reco Tech.ai
        {/* <span className="pipe-design">&nbsp;|&nbsp;</span>
        <span>
          Designed & Developed By:
          <a style={{ color: "#00c056" }} href="https://inoidsolutions.com/" target="_blank">
            &nbsp; iNoid Solutions
          </a>
        </span> */}
      </div>
    </div>
  );
};

export default Footer;
