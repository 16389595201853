import React, { useEffect, useState } from "react";
import { Card, Spinner } from "reactstrap";
// import { LargeLine } from "../../charts/campaign/CampaignCharts";
import { DropdownToggle, DropdownMenu, UncontrolledDropdown, DropdownItem, Label, Row, Col } from "reactstrap";
import DatePicker from "react-datepicker";
import "bootstrap-datepicker";
import "../assets/css/bootstrap-datepicker.css";
import { LineChartExample } from "../components/Component";
import { subDays } from "date-fns";
const AdminDashboardGraph = ({
  chartData,
  title,
  dd,
  setType,
  setdd,
  rangeStart,
  setRangeStart,
  rangeEnd,
  setRangeEnd,
  labelCheck,
  loader,
}) => {
  useEffect(() => {
    if (dd == "Range") {
      document.getElementById("date-picker-col").style.display = "block";
    } else {
      document.getElementById("date-picker-col").style.display = "none";
      setRangeStart("");
      setRangeEnd("");
    }
  }, [dd]);
  const handleDateChange = (date) => {
    console.log("date", date);
    // Subtract one day from the selected date
    // const adjustedDate = subDays(date);
    setRangeStart(date);
  };

  // useEffect(() => {
  //   setRangeEnd(rangeStart);
  // }, [rangeStart]);

  return (
    <Card className={`card-full`}>
      <div className="card-inner">
        <div className={`card-title-group d-flex  ${dd == "Range" ? "flex-column flex-md-row" : ""} `}>
          <div className="card-title">
            <h6 className="title">{title}</h6>
          </div>
          <Row lg={10}>
            <div className={`card-tools d-flex  align-items: center ${dd == "Range" ? "flex-column flex-sm-row" : ""}`}>
              <UncontrolledDropdown>
                <DropdownToggle
                  className={`dropdown-toggle dropdown-indicator btn  btn-outline-light btn-white  ${
                    dd == "Range" ? "mt-2 me-2 px-gs text-center" : dd == "Today" ? "px-gs" : ""
                  }`}
                >
                  {dd}
                </DropdownToggle>
                <DropdownMenu end className="dropdown-menu-xs">
                  <ul className="link-list-opt no-bdr">
                    <li className={dd === "Today" ? "active" : ""}>
                      <DropdownItem
                        href="#dropdownitem"
                        onClick={(e) => {
                          e.preventDefault();
                          setdd("Today");
                          setType("today");
                        }}
                      >
                        <span>Today</span>
                      </DropdownItem>
                    </li>
                    <li className={dd === "This Week" ? "active" : ""}>
                      <DropdownItem
                        href="#dropdownitem"
                        onClick={(e) => {
                          e.preventDefault();
                          setdd("This Week");
                          setType("this_week");
                        }}
                      >
                        <span>This Week</span>
                      </DropdownItem>
                    </li>
                    <li className={dd === "Last Week" ? "active" : ""}>
                      <DropdownItem
                        href="#dropdownitem"
                        onClick={(e) => {
                          e.preventDefault();
                          setdd("Last Week");
                          setType("last_week");
                        }}
                      >
                        <span>Last Week</span>
                      </DropdownItem>
                    </li>
                    <li className={dd === "This Month" ? "active" : ""}>
                      <DropdownItem
                        href="#dropdownitem"
                        onClick={(e) => {
                          e.preventDefault();
                          setdd("This Month");
                          setType("this_month");
                        }}
                      >
                        <span>This Month</span>
                      </DropdownItem>
                    </li>
                    <li className={dd === "Last Month" ? "active" : ""}>
                      <DropdownItem
                        href="#dropdownitem"
                        onClick={(e) => {
                          e.preventDefault();
                          setdd("Last Month");
                          setType("last_month");
                        }}
                      >
                        <span>Last Month</span>
                      </DropdownItem>
                    </li>
                    <li className={dd === "Range" ? "active" : ""}>
                      <DropdownItem
                        href="#dropdownitem"
                        onClick={(e) => {
                          e.preventDefault();
                          setdd("Range");
                          setType("range");
                        }}
                      >
                        <span>Range</span>
                      </DropdownItem>
                    </li>
                  </ul>
                </DropdownMenu>
              </UncontrolledDropdown>
              {dd === "Range" ? (
                <div className="form-group">
                  {/* <Label>Custom Range</Label> */}
                  <div className="form-control-wrap">
                    <div className="input-daterange date-picker-range input-group">
                      <DatePicker
                        selected={rangeStart}
                        onChange={handleDateChange}
                        selectsStart
                        startDate={rangeStart}
                        endDate={rangeEnd}
                        maxDate={new Date()}
                        className="form-control"
                        onKeyDown={(e) => {
                          e.preventDefault();
                        }}
                      />{" "}
                      <div className="input-group-addon datepicker-to">TO</div>
                      <DatePicker
                        selected={rangeEnd}
                        onChange={setRangeEnd}
                        startDate={rangeStart}
                        endDate={rangeEnd}
                        selectsEnd
                        minDate={rangeStart}
                        maxDate={new Date()}
                        className="form-control"
                        onKeyDown={(e) => {
                          e.preventDefault();
                        }}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <Col lg={9} id="date-picker-col"></Col>
          </Row>
        </div>
      </div>
      <div className="card-inner pt-0 mt-2">
        <div className="nk-cmwg2-ck chart-height">
          {loader ? (
            <div className="d-flex justify-content-center align-items-center" style={{ height: "inherit" }}>
              <Spinner />
            </div>
          ) : labelCheck.length ? (
            <LineChartExample legend={true} data={chartData} className="campaign-line-chart-s2" />
          ) : (
            <div className="d-flex justify-content-center align-items-center" style={{ height: "inherit" }}>
              No data found
            </div>
          )}
        </div>
      </div>
    </Card>
  );
};

export default AdminDashboardGraph;
