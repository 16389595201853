import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment/moment";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalBody, Button, Container } from "reactstrap";
import { Icon } from "../components/Component";
import instaIcon from "../images/instagram-icon.png";
import youtubeIcon from "../images/Youtube-icon.png";
import tiktokIcon from "../images/TikTok-icon.png";

function ConnectionListing({ list, YtoggleLg, setReBtnName, setLoader }) {
  const [isOpen, setIsOpen] = useState(false);
  const [modalLg, setModalLg] = useState(false);
  const [btnName, setBtnName] = useState("");
  const [platformName, setPlatformName] = useState("");

  const [deleteModal, setDeleteModal] = useState(false);
  const [platformId, setPlatformId] = useState("");
  const deleteModalFunction = () => setDeleteModal(!deleteModal);

  useEffect(() => {
    setPlatformName(list.platform_id == 1 ? "Instagram" : list.platform_id == 2 ? "TikTok" : "YouTube");
  }, []);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const toggleLg = () => setModalLg(!modalLg);
  const date = moment.unix(list.expiry_date, moment.ISO_8601);
  const todaysDate = moment();

  const deleteConnection = async (platformId) => {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/v1/users/delete-connection`,
      {
        platform_id: platformId,
      },
      {
        headers: {
          token: localStorage.getItem("accessToken"),
        },
      }
    );
    if (response.data.status) {
      // console.log(response.data.message);
      deleteModalFunction();
      // toggleToast();
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      // console.log(response.data.message);
    }
  };

  return (
    <div className="listing mt-3 p-3">
      <div className="container-fluid">
        <div className="row">
          <div className="d-flex col-5 col-lg-4 ">
            <img
              className="iconView"
              src={list.platform_id == 1 ? instaIcon : list.platform_id == 2 ? tiktokIcon : youtubeIcon}
              alt="Insta-Icon"
            />
            <div className="d-flex flex-column justify-content-center ps-3">
              <div className="fs-4 platform-name">{list.connection_name}</div>
              <div className="fs-6">Source / Destination</div>
            </div>
          </div>
          {/* <div className="d-flex align-items-center connection-name">{list.connection_name}</div> */}
          <div
            className={
              " col-3 d-flex align-items-center" +
              (!moment(date).isBefore(todaysDate) ? "active-status" : "inactive-status")
            }
          >
            {!moment(date).isBefore(todaysDate) ? "Active" : "Inactive"}
          </div>
          <div
            className={
              "col-3  d-flex  " + (moment(date).isBefore(todaysDate) ? "flex-column justify-content-around" : "")
            }
          >
            {date.format("L")}
            <div className={"  " + (moment(date).isBefore(todaysDate) ? "expired-date" : "")}>
              {moment(date).isBefore(todaysDate) ? `Expired` : ""}
            </div>
          </div>
          <div className="col-1 col-lg-2 ">
            <Dropdown isOpen={isOpen} toggle={toggle}>
              <DropdownToggle className="btn-action options-btn" color="primary">
                <Icon name="more-v" className="btn-color"></Icon>
              </DropdownToggle>
              <DropdownMenu className="dropdown-position">
                <ul className="link-list-opt">
                  {/* <li>
              <DropdownItem>
                <span>View Accounts</span>
              </DropdownItem>
            </li> */}
                  <li>
                    <DropdownItem
                      onClick={() => {
                        YtoggleLg(platformName, "Reconnect");
                        // setReBtnName("Reconnect");
                      }}
                    >
                      <span style={{ fontSize: "14px" }}>Reconnect</span>
                    </DropdownItem>
                  </li>
                  {/* <li>
              <DropdownItem
                onClick={() => {
                  setModalLg(!modalLg);
                  setBtnName("Save");
                }}
              >
                <span>Rename</span>
              </DropdownItem>
            </li> */}
                  <li>
                    <DropdownItem
                      onClick={() => {
                        deleteModalFunction(true);
                        setPlatformId(list.platform_id);
                        // deleteConnection(list.platform_id);
                      }}
                    >
                      <span style={{ fontSize: "14px" }}>Delete</span>
                    </DropdownItem>
                  </li>
                </ul>
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
      </div>

      <Modal size="lg" isOpen={modalLg} toggle={toggleLg}>
        <ModalBody className="connection-modal">
          <div className="mb-3 modal-head">
            <button className="close modal-close" onClick={toggleLg}>
              <Icon name="cross" />
            </button>
            <div className="d-flex justify-content-center fw-bolder fs-20px">YouTube</div>
          </div>
          <div className="d-flex flex-column mb-4">
            <label className="fs-4 mb-1">Name this Connection</label>
            <input className="connection-item-list" defaultValue="Untitled" />
          </div>
          <div className="d-flex justify-content-center mt-2">
            <button
              className="connect-btn"
              onClick={() => {
                setModalLg(!modalLg);
              }}
            >
              {btnName}
            </button>
          </div>
        </ModalBody>
      </Modal>

      <Modal size="lg" isOpen={deleteModal} toggle={deleteModalFunction}>
        <ModalBody className="connection-modal">
          <div className="mb-3 modal-head">
            <button className="close modal-close" onClick={deleteModalFunction}>
              <Icon name="cross" />
            </button>
            <div className="d-flex justify-content-center fw-bolder fs-20px text-center mx-4">
              Deleting this connection will delete all it's related workflows. Are you sure you want to delete
              connection?
            </div>
          </div>
          <div className="d-flex justify-content-around">
            <Button
              className="col-5 d-block"
              onClick={() => {
                setPlatformId("");
                deleteModalFunction();
              }}
            >
              No
            </Button>
            <Button
              className="col-5 d-block text-bg-primary"
              onClick={() => {
                setLoader(true);
                deleteConnection(platformId);
              }}
            >
              Yes
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default ConnectionListing;
