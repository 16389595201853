import React, { useEffect, useState } from "react";
import axios from "axios";
import Head from "../layout/head/Head";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockBetween,
  BlockTitle,
  Icon,
  Button,
  Row,
} from "../components/Component";
import Accordion from "./Accordian/accordian";
import { useNavigate } from "react-router-dom";
import { Spinner } from "reactstrap";
import toast from "react-hot-toast";

const Workflows = () => {
  const [workflowData, setWorkflowData] = useState([]);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getAllWorkflows();
  }, []);

  const getAllWorkflows = async () => {
    try {
      setLoader(true);
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/v1/workflow/get-all-workflow`, {
        headers: {
          token: localStorage.getItem("accessToken"),
        },
      });
      if (response.data.status) {
        // toast.success(response.data.message);
        setTimeout(() => {
          setWorkflowData(response.data.data);
          setLoader(false);
        }, 1000);
      } else {
        toast.error(response.data.message);
        setTimeout(() => {
          setWorkflowData(response.data.data);
          setLoader(false);
        }, 1000);
      }
    } catch (err) {
      setLoader(false);
      toast.error(err.message);
    }
  };

  return (
    <React.Fragment>
      <Head title="Workflows" />
      <section className="container-fluid">
        <BlockHead size="sm" className="mx-4">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>Workflows</BlockTitle>
              {/* <BlockDes className="text-soft">
                <p>Welcome to Campaign Management Dashboard.</p>
              </BlockDes> */}
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <div className="toggle-expand-content">
                  <ul className="nk-block-tools g-3">
                    <li>
                      <Button
                        className="btn-dim btn-white btn-outline-light"
                        onClick={() => navigate("/createWorkFlow")}
                      >
                        <Icon name="plus"></Icon>
                        <span>
                          <span className="d-block" style={{ fontSize: "14px" }}>
                            CREATE A NEW WORKFLOW
                          </span>
                        </span>
                      </Button>
                    </li>
                    {/* <li className="nk-block-tools-opt">
                      <Button className="btn-primary">
                        <Icon name="reload"></Icon>
                      </Button>
                    </li> */}
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block className="responsive-workflow mx-3">
          <Row>
            {workflowData.length > 0 ? (
              workflowData.map((data, index) => <Accordion key={index} data={data} setLoader={setLoader} />)
            ) : loader ? (
              <div className="d-flex justify-content-center mt-4">
                <Spinner color="primary" />
              </div>
            ) : (
              <h4 className="text-center pt-5 mt-5">No workflow found. Please create a new workflow.</h4>
            )}
          </Row>
        </Block>
      </section>
    </React.Fragment>
  );
};

export default Workflows;
