import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import Head from "../layout/head/Head";
import videoIcon from "../images/video-icon.png";
import { Spinner } from "reactstrap";
import {
  PreviewCard,
  ReactDataTable,
  Button,
  BlockHead,
  BlockHeadContent,
  BlockBetween,
  BlockTitle,
} from "../components/Component";
import toast from "react-hot-toast";
import moment from "moment/moment";

function PostedVideosHistory() {
  const [contentData, setContentData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [rowsPerPageS, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowCount, setRowCount] = useState(1);

  useEffect(() => {
    getAllVideoData();
  }, [rowsPerPageS, currentPage]);

  const getAllVideoData = async () => {
    setLoader(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/v1/users/get-all-old-video-data`,
        {
          offset: Number(currentPage),
          limit: Number(rowsPerPageS),
        },
        {
          headers: {
            token: localStorage.getItem("accessToken"),
          },
        }
      );
      if (response.data.status) {
        // console.log(response.data.data.totalRowData);
        setRowCount(response.data.data.totalRowData);
        // toast.success(response.data.message);
        setTimeout(() => {
          setContentData(response.data.data.oldVideoData);
          setLoader(false);
        }, 1000);
      } else {
        // toast.success(response.data.message);
        setTimeout(() => {
          setContentData(response.data.data);
          setLoader(false);
        }, 1000);
      }
    } catch (err) {
      setLoader(false);
      toast.error(err.message);
    }
  };

  const videoPublish = async (id) => {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/v1/users/republish-video`,
      {
        id: id,
      },
      {
        headers: {
          token: localStorage.getItem("accessToken"),
        },
      }
    );
    if (response.data.status) {
      // toast.success(response.data.message);
      setTimeout(() => {
        getAllVideoData();
      }, 100);
    } else {
      toast.error(response.data.message);
      setTimeout(() => {
        getAllVideoData();
      }, 100);
    }
  };

  const dataTableColumns = [
    {
      name: "Media",
      // selector: (row) => row.thumbnailUrl,
      // sortable: true,
      style: { minHeight: "100px" },
      cell: (row) =>
        row.jsondata.media_url ? (
          <a href={row.jsondata.permalink ? row.jsondata.permalink : row.jsondata.media_url} target="_blank">
            <img className="video-thumbnail" src={videoIcon} alt="Media-Thumbnail" />
          </a>
        ) : (
          <a href={`https://www.youtube.com/watch?v=${row.jsondata.media_id}`} target="_blank">
            <img className="video-thumbnail" src={videoIcon} alt="Media-Thumbnail" />
          </a>
        ),
    },
    {
      name: "Source",
      selector: (row) => (row.source_id == 1 ? "Instagram" : row.source_id == 2 ? "TikTok" : "Youtube"),
      // sortable: true,
      // hide: 370,
    },
    {
      name: "Destination",
      style: { width: "150px" },
      selector: (row) => (row.destination_id == 1 ? "Instagram" : row.destination_id == 2 ? "TikTok" : "Youtube"),
      // sortable: true,
      // hide: 370,
    },
    {
      name: "Date",
      selector: (row) => moment(row.updated_at).format("MM-DD-YYYY"),
      // sortable: true,
      // hide: 370,
    },
    {
      name: "Time",
      selector: (row) => moment(row.updated_at).format("LT"),
      // sortable: true,
      // hide: 370,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      // sortable: true,
      // hide: "sm",
      style: { paddingLeft: "4px" },
      cell: (row) => (
        <span
          className={`tb-status ms-1 text-${
            row.status == 2 ? "success" : row.status == 1 ? (row.run_task_count == 4 ? "danger" : "warning") : "danger"
          }`}
          style={{ fontSize: "14px" }}
        >
          {row.status == 2
            ? row.republished == 1
              ? "Success(Re-Published)"
              : "Success"
            : row.status == 1
            ? row.run_task_count == 4
              ? "Error(Re-Published)"
              : "In Process"
            : row.status == 3
            ? "In Process"
            : row.status == 5 && row.republished == 1
            ? "Error(Re-Published)"
            : "Error"}
        </span>
      ),
    },
    {
      name: "Action",
      // selector: (row) => row.action,
      // sortable: true,
      // hide: "sm",
      cell: (row) =>
        (row.status == 4 || row.run_task_count == 4) &&
        row.status != 5 &&
        !moment(row.republished_time).add(24, "h").isBefore(moment()) ? (
          <Button onClick={() => videoPublish(row.id)} className="btn-dim responsive_button_custom" color="primary">
            Republish
          </Button>
        ) : (
          ""
        ),
    },
  ];

  return (
    <Fragment>
      <Head title="All Posted Videos" />
      {loader ? (
        <div className="d-flex justify-content-center mt-4">
          <Spinner color="primary" />
        </div>
      ) : (
        <section className="container">
          <BlockHead size="sm" className="mx-4">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle page>All Posted Videos</BlockTitle>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <PreviewCard className="mt-5">
            <ReactDataTable
              data={contentData}
              columns={dataTableColumns}
              setRowsPerPage={setRowsPerPage}
              rowsPerPageS={rowsPerPageS}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              rowCount={rowCount}
              pagination
              className="nk-tb-list"
            />
          </PreviewCard>
        </section>
      )}
    </Fragment>
  );
}

export default PostedVideosHistory;
