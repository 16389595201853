import React from "react";
import { Col, Container, Row } from "reactstrap";
import group2 from "../../assets/LandingPage/group2.png";

const HomeComp2 = () => {
  const navigate = () => {
    window.location.href = `${process.env.REACT_APP_PUBLIC_SUBDOMAIN_URL}/login`;
  };
  return (
    <div>
      <Container>
        <div className="d-flex justify-content-center home_comp_second_title ">It's Simple</div>
        <div className="row m-0 ">
          <div className="home_block_description col-lg-6 col-sm-12 pb-4 d-flex align-items-center">
            <div className="component-responsive">
              <div className="home_comp_green">Connect</div>
              <div className="home_comp_para">
                With a few clicks you can connect all yours socials from your main to your secondary ones!
              </div>
              <div className="mb-2">
                <button className="get_started_btn_black" onClick={navigate}>
                  Get Started
                </button>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-sm-12 ps-lg-5">
            <img src={group2} alt="group-image" />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default HomeComp2;
