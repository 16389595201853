import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import Head from "../layout/head/Head";
import { useNavigate } from "react-router-dom";
import { Col, Row, Spinner } from "reactstrap";
import {
  BlockHead,
  BlockHeadContent,
  BlockBetween,
  BlockTitle,
  Icon,
  PreviewCard,
  ReactDataTable,
  Button,
} from "../components/Component";
import instaIcon from "../images/instagram-icon.png";
import youtubeIcon from "../images/Youtube-icon.png";
import tiktokIcon from "../images/TikTok-icon.png";
import toast from "react-hot-toast";
import moment from "moment/moment";

function AdminUserWorkflows() {
  const [contentData, setContentData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [rowsPerPageS, setRowsPerPage] = useState(10);

  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("userId")) {
      getAllUserWorkflowInfo();
    } else {
      navigate("/");
    }
  }, []);

  const getAllUserWorkflowInfo = async () => {
    setLoader(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/v1/admin/get-all-user-workflows`,
        {
          user_id: localStorage.getItem("userId"),
        },
        {
          headers: {
            token: localStorage.getItem("adminToken"),
          },
        }
      );
      if (response.data.status) {
        toast.success(response.data.message);
        setTimeout(() => {
          setContentData(response.data.data);
          setLoader(false);
        }, 1000);
      } else {
        toast.success(response.data.message);
        setTimeout(() => {
          setContentData(response.data.data);
          setLoader(false);
        }, 1000);
      }
    } catch (err) {
      setLoader(false);
      toast.error(err.message);
    }
  };

  const dataTableColumns = [
    // {
    //   name: "User ID",
    //   selector: (row) => row.id,
    //   // sortable: true,
    // },
    {
      name: "Workflow",
      style: { width: "500px" },
      //   selector: (row) => row.email,
      // sortable: true,
      // hide: 370,
      cell: (row) => (
        <div className="d-flex align-items-center header-listing">
          <div className="d-flex align-items-center">
            <img
              className="lt-icon"
              src={row.source_id == 1 ? instaIcon : row.source_id == 2 ? tiktokIcon : youtubeIcon}
              alt="Insta-icon"
            />
            <h6 className="ms-2">{row.source_id == 1 ? "Instagram" : row.source_id == 2 ? "TikTok" : "Youtube"}</h6>
          </div>
          <Col className="arrow-col" lg={1} md={1} sm={1} xs={1}>
            <Row className="arrow-design">
              <Icon className="forward-arrow" name="forward-alt-fill" />
            </Row>
            <Row></Row>
          </Col>
          <div className="d-flex align-items-center">
            <img
              className="lt-icon"
              src={row.destination_id == 1 ? instaIcon : row.destination_id == 2 ? tiktokIcon : youtubeIcon}
              alt="TikTok-icon"
            />
            <h6 className="ms-2">
              {row.destination_id == 1 ? "Instagram" : row.destination_id == 2 ? "TikTok" : "Youtube"}
            </h6>
          </div>
        </div>
      ),
    },
    {
      name: "Workflow Name",
      selector: (row) => row.name,
      // sortable: true,
      // hide: 370,
    },
    {
      name: "View",
      cell: (row) => (
        <Button
          className="btn-dim"
          color="primary"
          onClick={() => {
            localStorage.setItem("source_id", row.source_id);
            localStorage.setItem("destination_id", row.destination_id);
            navigate("/user-workflow-listing");
          }}
        >
          <Icon name="eye-fill" />
        </Button>
      ),
      width: "80px",
    },
  ];

  return (
    <Fragment>
      <Head title="Video Listing" />
      {loader ? (
        <div className="d-flex justify-content-center mt-4">
          <Spinner color="primary" />
        </div>
      ) : (
        <section className="container">
          <BlockHead size="sm" className="mb-4">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle page>User Workflows</BlockTitle>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <Button
            onClick={() => {
              localStorage.removeItem("userId");
              navigate(-1);
            }}
            className="btn-dim btn-white btn-outline-light me-5 mb-4"
          >
            <Icon name="arrow-left-fill-c" />
            <div className="back-btn">Back</div>
          </Button>

          <PreviewCard className="mt-5">
            <ReactDataTable
              data={contentData}
              columns={dataTableColumns}
              setRowsPerPage={setRowsPerPage}
              rowsPerPageS={rowsPerPageS}
              type={1}
              className="nk-tb-list"
            />
          </PreviewCard>
        </section>
      )}
    </Fragment>
  );
}

export default AdminUserWorkflows;
