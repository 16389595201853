import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import Head from "../layout/head/Head";
import { useNavigate } from "react-router-dom";
import { Col, Row, Spinner } from "reactstrap";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockBetween,
  BlockTitle,
  Icon,
  PreviewCard,
  ReactDataTable,
  Button,
} from "../components/Component";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import toast from "react-hot-toast";
import moment from "moment/moment";
import debounce from "lodash.debounce";
function AdminUserListing() {
  const [contentData, setContentData] = useState([]);
  const [rowsPerPageS, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [loader, setLoader] = useState(false);
  const [searchLoader, setSearchLoader] = useState(false);
  const [rowCount, setRowCount] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [search, setSearch] = useState(false);
  const navigate = useNavigate();

  // useEffect(() => {
  //   getAllUsersInfo();
  // }, []);

  useEffect(() => {
    setSearchLoader(true);
    getAllUsersInfo();
    return () => {
      getAllUsersInfo.cancel();
    };
  }, [rowsPerPageS, currentPage, searchText]);

  const getAllUsersInfo = debounce(async () => {
    // if (searchText === "") {
    //   setLoader(true);
    // } else {
    setSearchLoader(true);
    // }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/v1/admin/get-all-users`,
        {
          offset: currentPage,
          limit: Number(rowsPerPageS),
          param: searchText,
        },
        {
          headers: {
            token: localStorage.getItem("adminToken"),
          },
        }
      );
      if (response.data.status) {
        setRowCount(response.data.data.totalRowData);
        // toast.success(response.data.message);
        // setTimeout(() => {
        setContentData(response.data.data.usersData);
        // setLoader(false);

        setSearchLoader(false);
        // }, 1000);
      } else {
        toast.error(response.data.message);
        setTimeout(() => {
          setContentData(response.data.data);

          // setLoader(false);
          setSearchLoader(false);
        }, 1000);
      }
    } catch (err) {
      setLoader(false);
      toast.error(err.message);
    }
  }, 800);

  // const getAllUsersInfo = debounce(async () => {
  //   try {
  //     setSearchLoader(true);
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_SERVER_URL}/v1/admin/get-all-users`,
  //       {
  //         offset: currentPage,
  //         limit: Number(rowsPerPageS),
  //         param: searchText,
  //       },
  //       {
  //         headers: {
  //           token: localStorage.getItem("adminToken"),
  //         },
  //       }
  //     );

  //     if (response.data.status) {
  //       console.log("show rows", response.data.data.totalRowData);
  //       setRowCount(response.data.data.totalRowData);
  //       setContentData(response.data.data.getAllUsers);
  //       setSearchLoader(false);
  //     }
  //   } catch (err) {
  //     setSearchLoader(false);
  //   }
  // }, 800);

  const blockUser = async (id) => {
    setLoader(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/v1/admin/block-user`,
        {
          user_id: id,
        },
        {
          headers: {
            token: localStorage.getItem("adminToken"),
          },
        }
      );
      if (response.data.status) {
        toast.success(response.data.message);
        setTimeout(() => {
          getAllUsersInfo();
        }, 1000);
      } else {
        setLoader(false);
        toast.error(response.data.message);
      }
    } catch (err) {
      setLoader(false);
      toast.error(err.message);
    }
  };

  const unblockUser = async (id) => {
    setLoader(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/v1/admin/unblock-user`,
        {
          user_id: id,
        },
        {
          headers: {
            token: localStorage.getItem("adminToken"),
          },
        }
      );
      if (response.data.status) {
        toast.success(response.data.message);
        setTimeout(() => {
          getAllUsersInfo();
        }, 1000);
      } else {
        setLoader(false);
        toast.error(response.data.message);
      }
    } catch (err) {
      setLoader(false);
      toast.error(err.message);
    }
  };

  const deleteUser = async (id) => {
    setLoader(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/v1/admin/delete-user`,
        {
          user_id: id,
        },
        {
          headers: {
            token: localStorage.getItem("adminToken"),
          },
        }
      );
      if (response.data.status) {
        toast.success(response.data.message);
        setTimeout(() => {
          getAllUsersInfo();
        }, 1000);
      } else {
        setLoader(false);
        toast.error(response.data.message);
      }
    } catch (err) {
      setLoader(false);
      toast.error(err.message);
    }
  };

  const selectNameFn = (id) => {
    // console.log(id);
    navigate(`/userDetails/${id}`);
  };

  const dataTableColumns = [
    // {
    //   name: "User ID",
    //   selector: (row) => row.id,
    //   // sortable: true,
    // },
    {
      name: "Name",
      style: { width: "150px" },
      selector: (row) => (
        <div
          className="text-capitalize user_selection"
          onClick={() => {
            selectNameFn(row.id);
          }}
          style={{ cursor: "pointer" }}
        >
          {row.full_name}
        </div>
      ),
      // sortable: true,
      // hide: 370,
    },
    {
      name: "Email",
      style: { width: "900px" },
      selector: (row) => row.email,
      // sortable: true,
      // hide: 370,
    },

    {
      name: "Status",
      //   selector: (row) => (row.dob_date ? row.dob_date : "-"),
      // sortable: true,
      // hide: "sm",
      style: { width: "100px" },

      cell: (row) => (
        <span className={`tb-status ms-1 text-${row.status == 1 ? "success" : row.status == 2 ? "warning" : "danger"}`}>
          {row.status == 1 ? "Active" : row.status == 2 ? "Blocked" : "Deleted"}
        </span>
      ),
    },
    {
      name: "Action",
      // selector: (row) => row.action,
      // sortable: true,
      // hide: "sm",
      style: { width: "100px" },

      cell: (row) => (
        <UncontrolledDropdown>
          <DropdownToggle className="dropdown-toggle btn btn-icon btn-light">
            <Icon name="more-h"></Icon>
          </DropdownToggle>
          <DropdownMenu>
            <ul className="link-list-opt no-bdr">
              <li>
                <DropdownItem
                  tag="a"
                  href="#links"
                  onClick={(ev) => {
                    ev.preventDefault();
                    localStorage.setItem("userId", row.id);
                    navigate("/user-workflows");
                  }}
                >
                  <Icon name="repeat-fill"></Icon>
                  <span>Workflows</span>
                </DropdownItem>
              </li>
              <li>
                {row.status == 1 ? (
                  <DropdownItem
                    tag="a"
                    href="#links"
                    onClick={(ev) => {
                      ev.preventDefault();
                      blockUser(row.id);
                    }}
                  >
                    <Icon name="user-cross-fill"></Icon>
                    <span>Block</span>
                  </DropdownItem>
                ) : (
                  <DropdownItem
                    tag="a"
                    href="#links"
                    onClick={(ev) => {
                      ev.preventDefault();
                      unblockUser(row.id);
                    }}
                  >
                    <Icon name="user-check-fill"></Icon>
                    <span>Unblock</span>
                  </DropdownItem>
                )}
              </li>
              {/* <li>
                <DropdownItem
                  tag="a"
                  href="#links"
                  onClick={(ev) => {
                    ev.preventDefault();
                    deleteUser(row.id);
                  }}
                >
                  <Icon name="trash-fill"></Icon>
                  <span>Delete</span>
                </DropdownItem>
              </li> */}
            </ul>
          </DropdownMenu>
        </UncontrolledDropdown>
      ),
    },
  ];

  return (
    <div className="pb-4">
      <Head title="Users" />
      {loader ? (
        <div className="d-flex justify-content-center ">
          <Spinner className="animate-spin text-center text-blue-800" color="primary" />
        </div>
      ) : (
        <section className="container">
          <BlockHead size="sm" className="mx-4">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle page>Users</BlockTitle>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <PreviewCard className="">
            <ReactDataTable
              data={contentData}
              columns={dataTableColumns}
              setRowsPerPage={setRowsPerPage}
              rowsPerPageS={rowsPerPageS}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              rowCount={rowCount}
              type={2}
              setSearchText={setSearchText}
              searchText={searchText}
              pagination
              loader={searchLoader}
              className="nk-tb-list"
            />
          </PreviewCard>
        </section>
      )}
    </div>
  );
}

export default AdminUserListing;
